export class RsUtils {
  /** Allow specific keys and key combinations */
  public static isAllowableKeyOrKeyCombination(event: KeyboardEvent): boolean {
    const
      allowedUncombinedKeys = [
        'Enter',
        'Backspace',
        'Tab',
        'Escape',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Home',
        'End'
      ],
      keysAllowedToBeCombinedWithCtrl = [
        'a',
        'x',
        'c',
        'v'
      ];

    return allowedUncombinedKeys.includes(event.key)
            || ((event.ctrlKey || event.metaKey) && keysAllowedToBeCombinedWithCtrl.includes(event.key));
  }

  /** Fake user typing into the input field + triggers keyDown, keyUp, keyPress on the way
     *
     * If keydown event is prevented by some keydown rules, it won't type the char
     *
     * Used to test form field directives
     * */
  public static fakeUserTyping(
    value: string,
    inputElement: HTMLInputElement
  ): void {
    for (const char of value) {
      const
        keyDownMock = new KeyboardEvent('keydown', { key: char, cancelable: true, bubbles: true }),
        keyUpMock = new KeyboardEvent('keyup', { cancelable: true, bubbles: true }),
        keyPressMock = new KeyboardEvent('keypress', { cancelable: true, bubbles: true });

      inputElement.dispatchEvent(keyDownMock);

      if (
        !keyDownMock.defaultPrevented
                && !keyUpMock.defaultPrevented
                && !keyPressMock.defaultPrevented
      ) {
        inputElement.value = inputElement.value.concat(char);
        inputElement.dispatchEvent(new Event('input'));
      }

      inputElement.dispatchEvent(keyUpMock);
      inputElement.dispatchEvent(keyPressMock);
    }
  }

  public static trimEmptyHttpParams(params: Object): {} {
    const httpParams: Record<string, unknown> = {};

    Object.entries(params)
      .forEach(([key, value]): void => {
        switch (Object.prototype.toString.call(value)) {

          case '[object Array]':
            if ((value as []).length) {
              httpParams[key] = value;
            }
            break;

          case '[object Object]':
            if (Object.entries(value as object).length) {
              httpParams[key] = value;
            }
            break;

          default:
            if (value) {
              httpParams[key] = value;
            }
            break;
        }
      });

    return httpParams;
  }

  public static isElementInViewport(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
