import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

export interface TableOptions<SortActiveType> extends TableOptionsEvents<SortActiveType> {
  resultCount: number;
}

export interface TableOptionsEvents<SortActiveType> {
  pageEvent: PageEvent,
  sort: {
    active: SortActiveType,
    direction: Sort['direction']
  };
}
