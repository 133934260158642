import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { delay, Subject } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { takeUntil } from 'rxjs/operators';
import { MatFormField } from '@angular/material/form-field';
import '../../3rd-party-libraries/module-augmentation';

/** Will append a clear button to the mat-form-field providing a button to reset the value of the form field
 *
 * Usage:
 *
 * ```html
 <mat-form-field
 rsAddClearFormFieldButton
 OPTIONAL > [rsClearFormFieldButtonCallback]='Function.bind(this)'
 OPTIONAL > [rsClearFormFieldButtonDisabled]='boolean'
 OPTIONAL > (onRsClearFormFieldButtonClicked)='Function()'
 >
 ...
 </mat-form-field>
 ```
 */
@Directive({
  selector: '[rsAddClearFormFieldButton]',
  standalone: true
})
export class RsAddClearFormFieldButtonDirective implements OnInit, AfterViewInit {

  /** Overrides default click events (reset value) with provided custom callback
   *
   * Usage:
   *
   * ```html
   <mat-form-field [rsClearFormFieldButtonCallback]='Function.bind(this) > Function to execute after click event'>
   ...
   </mat-form-field>
   ```
   */
  @Input('rsClearFormFieldButtonCallback') public rsClearFormFieldButtonCallback?: Function;
  /** Emit click event on rsClearFormFieldButton
   *
   * Can be used for triggering custom callback after the default one
   *
   * Usage:
   *
   * ```html
   <mat-form-field (onRsClearFormFieldButtonClicked)='Function() > Function to execute after click event'>
   ...
   </mat-form-field>
   ```
   */
  @Output('onRsClearFormFieldButtonClicked') public onRsClearFormFieldButtonClicked: EventEmitter<MouseEvent> = new EventEmitter();
  private closeButton!: HTMLElement;
  private inputField!: AbstractControl;
  private buttonWrapper!: HTMLElement;
  private clearButtonLandingTag!: HTMLElement;
  private closeIcon!: HTMLElement;
  private formControlName?: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Optional() public ngControl: NgControl,
    @Optional() private matFormField: MatFormField,
    private readonly hostElement: ElementRef,
    private readonly renderer: Renderer2,
    private readonly viewContainerRef: ViewContainerRef
  ) {
  }

  private _buttonDisabled: boolean = false;

  /** disable or not clear button
   *
   * Usage:
   *
   * ```html
   <mat-form-field [rsClearFormFieldButtonDisabled]='boolean value'>
   ...
   </mat-form-field>
   ```
   **/
  @Input('rsClearFormFieldButtonDisabled')
  public set buttonDisabled(isDisabled: boolean) {
    this._buttonDisabled = isDisabled;
    this.shouldDisplayButton();
  }

  public ngOnInit(): void {
    this.createAndRenderCloseButton();
  }

  public ngAfterViewInit(): void {
    this.addListenersToButton();
    this.appendCloseButtonToParent();
    // Add mat-mdc-form-field-has-icon-suffix class to respect material rules
    this.hostElement.nativeElement.classList.add('mat-mdc-form-field-has-icon-suffix')
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createAndRenderCloseButton(): void {
    this.closeButton = this.viewContainerRef.createComponent(MatIconButton).instance._elementRef.nativeElement;
    this.closeIcon = this.viewContainerRef.createComponent(MatIcon).instance._elementRef.nativeElement;

    this.clearButtonLandingTag = this.hostElement.nativeElement.querySelector('.mat-mdc-form-field-flex') as HTMLDivElement;

    this.buttonWrapper = this.renderer.createElement('div');
    this.renderer.setAttribute(this.closeButton, 'type', 'button');
    this.renderer.setAttribute(this.closeButton, 'tabindex', '-1');

    this.renderer.addClass(this.closeButton, 'rs-clear-form-field-button');
    this.renderer.addClass(this.buttonWrapper, 'mat-mdc-form-field-icon-suffix');
    this.renderer.addClass(this.buttonWrapper, 'rs-clear-form-field-button--wrapper');
  }

  private addListenersToButton(): void {
    const ngControl = this.ngControl ? this.ngControl : this.matFormField._control.ngControl; // See explanation (*1) below

    this.inputField = ngControl?.control as AbstractControl;
    this.formControlName = (ngControl as NgControl)?.name?.toString();

    this.renderer.addClass(this.closeButton, 'rs-clear-form-field-button-' + this.formControlName);

    this.inputField
      .valueChanges
      .pipe(delay(0), takeUntil(this.destroy$))
      .subscribe(() => {
        this.shouldDisplayButton();
      });

    this.shouldDisplayButton(); // In case of default value
    this.closeButton.addEventListener('click', this.onClick.bind(this));
  }

  private appendCloseButtonToParent(): void {
    this.renderer.appendChild(
      this.closeIcon,
      this.renderer.createText('close')
    );

    const iconWrapper = this.renderer.createElement('div');

    this.renderer.addClass(iconWrapper, 'rs-clear-form-field-button-icon-wrapper');
    this.renderer.appendChild(iconWrapper, this.closeIcon);
    this.renderer.appendChild(this.closeButton, iconWrapper);
    this.renderer.appendChild(this.buttonWrapper, this.closeButton);
    this.renderer.appendChild(this.clearButtonLandingTag, this.buttonWrapper);
  }

  private shouldDisplayButton(): void {
    if (!this.closeButton) {
      return;
    }

    const
      formFieldHasValue = this.ngControl?.valueAccessor ? Boolean(this.matFormField._control.value) : this.inputField?.rsHasValue(),
      isReadOnly = (this.matFormField._elementRef.nativeElement.classList.contains('rs-datepicker')
        ? false
        : (this.matFormField._control as { _readonly?: boolean })['_readonly']
      );

    this.renderer[
      formFieldHasValue && !this._buttonDisabled && !isReadOnly ? 'removeClass' : 'addClass'
    ](
      this.buttonWrapper,
      'rs-clear-form-field-button--disabled'
    );
  }

  private onClick(event: MouseEvent): void {
    this.rsClearFormFieldButtonCallback ? this.rsClearFormFieldButtonCallback() : this.inputField.reset();
    this.onRsClearFormFieldButtonClicked.emit(event);
    this.inputField.markAsTouched();
    this.inputField.markAsDirty();
    event.stopPropagation();
  }
}

/** (*1)
 * We first check the ngControl because if it is an external form field implementing the ControlValueAccessor.
 * In this specific case the formControl that we are looking for is passed to the matFormField via the external component,
 * and formControl inside the matFormField is irrelevant as we target the formControl of the form and not the one of the custom field.
 * ex: here we see that the formControlName is passed via the external component
 <ui-renta-activity-task-select
 [required]="true"
 formControlName="activityTask"
 [disabled]="!companyThresholdForm.get('activityDefinition')?.valid"
 >
 </ui-renta-activity-task-select>
 * */
