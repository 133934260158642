@if (showSteps) {
<section class="rs-stepper-header">
  <div class="rs-stepper-labels">

    @for (step of steps; track step; let i = $index; ) {
    @if (selectedIndex === i) {
    <div>
      <!-- If there is a label template, use it. -->
      <span class="rs-step-label-template">
        @if (step.stepLabel) {
        <ng-container [ngTemplateOutlet]="step.stepLabel.template">
        </ng-container>
        }
      </span>
      <!-- If there is no label template, fall back to the text label. -->
      <span class="rs-step-label-text">
        {{ step.label }}
      </span>
    </div>
    }
    }
  </div>

  <div class="rs-current-step-displayer">
    {{ 'STEP' | translate }} {{ selectedIndex + 1 }}/{{ steps.length }}
  </div>
</section>
}

<section class="rs-stepper-content">
  @for (step of steps; track step; let i = $index) {
  <div
    [@stepTransition]="_getAnimationDirection(i)"
    [attr.aria-expanded]="selectedIndex === i"
    [attr.aria-labelledby]="_getStepLabelId(i)"
    [id]="_getStepContentId(i)"
    class="rs-mat-horizontal-stepper-content"
    role="tabpanel"
  >
    <!-- Content from the CdkStep is projected here -->
    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
  </div>
  }
</section>