import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatePipe, NgIf } from '@angular/common';
import { CreationEditionUserPipe } from '../../pipes/creation-edition-user/creation-edition-user.pipe';
import { AuditTrail } from '../../models/audit';
import { RsNullDashPipe } from '../../pipes/rs-null-dash/rs-null-dash.pipe';

@Component({
  selector: 'rs-audit-trail',
  templateUrl: './rs-audit-trail.component.html',
  styleUrls: ['./rs-audit-trail.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    DatePipe,
    NgIf,
    CreationEditionUserPipe,
    RsNullDashPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  host: { class: 'rs-audit-trail' }
})
export class RsAuditTrailComponent {
  @Input({ required: true }) public auditTrail!: AuditTrail;
  @Input() public multiline = true;

  constructor(private readonly translateService: TranslateService) {
  }

  public get currentLanguage(): string {
    return this.translateService.currentLang;
  }
}
