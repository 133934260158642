import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';

/** @deprecated DO NOT USE ANYMORE !!!
 *
 * @description
 * Please ONLY import the ones you need!
 **/
@NgModule({
  declarations: [],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBadgeModule,
    MatStepperModule,
    CdkStepperModule
  ],
  exports: [
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBadgeModule,
    MatStepperModule,
    CdkStepperModule
  ]
})
export class CustomMaterialModule {

  private customIconsFolder: string;
  private customIconsArray: string[];

  public constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    /** Custom icons folder path */
    this.customIconsFolder = '../../assets/icons/';

    /** Custom icons file name list */
    this.customIconsArray = [
      'file-pdf-outline',
      'arrow-left-alt',
      'file-excel',
      'king',
      'person',
      'file-outline',
      'file-image'
    ];

    /** Add custom icons to mat icon registry */
    this.customIconsArray.forEach((iconName): void => {
      this.matIconRegistry.addSvgIcon(
        `${iconName}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.customIconsFolder}${iconName}.svg`)
      );
    });
  }
}
