import { NgModule } from '@angular/core';
import { RsMessageHandlerComponent } from './rs-message-handler/rs-message-handler.component';
import { RsMessagesHandlerService } from './services/rs-messages-handler.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    RsMessageHandlerComponent,
  ],
  exports: [ToastrModule],
  providers: [RsMessagesHandlerService],
})
export class RsMessageHandlerModule {
}
