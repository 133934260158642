export interface StreetAndHouseNumberAddress {
    streetAndHouseNumber: string;
    postalCode: string;
    city: string;
}

export interface OldAddress {
    city: string;
    country: string;
    houseNumber: string;
    street: string;
    zipCode: ValueObject;
    boxNumber?: string;
}

export interface ValueObject {
    value: string | number;
}


export interface NewAddress {
    address: string;
    postalCode: string;
    city: string;
}

export interface Address {
    city?: string;
    country?: Country;
    boxNumber?: string;
    houseNumber?: string;
    postalCode?: string;
    street?: string;
}


enum Country {
    BELGIUM = 'belgium',
    LUXEMBOURG = 'luxembourg',
    FRANCE = 'france',
    GERMANY = 'germany',
    THE_NETHERLANDS = 'netherlands',
}

