<div
  *ngIf="show"
  class="spinnerAreaOverlay"
>
  <div class="row w-100 g-0 horizontal-align-{{hAlignMessage}} vertical-align-{{vAlignMessage}}">
    <div
      [ngClass]="{
        'text-center col-12': hAlignMessage === 'center'
      }"
      class="col-auto align-self-center"
    >

      <div *ngIf="isMobiSkin; else rsLoader">
        <mat-progress-spinner
          class="mobi-section-spinner"
          diameter="40"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>

      <ng-template #rsLoader>
        <img
          class="me-5px"
          src="assets/loaders/rs-loader-light-1.svg"
        />
      </ng-template>

    </div>

    <div
      [ngClass]="{
        'text-center col-12': hAlignMessage === 'center'
      }"
      class="col ellipsis align-self-center"
    >
      <p class="ellipsis">
        {{ message | translate }}
      </p>
    </div>
  </div>
</div>