import { Observable, OperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

/** RxJS operator `rsTapOnce` invokes a provided function only once for the first emitted value from an Observable.
 * ***
 * @param {Function<T>} fn - The function to be invoked with the first emitted value.
 * @returns {OperatorFunction<T, T>} - An RxJS operator function that applies the tapping logic.
 *
 * This operator is useful for scenarios where you want to perform an action (represented by the `fn` function) only on the initial value emitted by an Observable. It essentially taps into the Observable stream, checks if it's the first value, and executes the `fn` function if it is. Subsequent emissions are ignored.
 *
 * **Usage:**
 *
 * ```typescript
 * import { of, tap } from 'rxjs';
 * import { rsTapOnce } from './your-file-path'; // Replace with actual path

 * const source$ = of(1, 2, 3);

 * source$.pipe(
 *   rsTapOnce(value => console.log('First value:', value)),
 *   tap(value => console.log('All values:', value))
 * )
 * .subscribe();
 *
 * // Output:
 * // First value: 1
 * // All values: 1
 * // All values: 2
 * // All values: 3
 * ```
 *
 * In this example, `console.log('First value:', value)` will only be called once with the first emitted value (1). The `tap(value => console.log('All values:', value))` will be called for all emitted values (1, 2, 3).
 */
export function rsTapOnce<T>(
    fn: (v: T) => void
): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> => {
    let isFirst = true;

    return source$.pipe(
      tap((value: T) => {
        if (isFirst) {
          fn(value);
        }
        isFirst = false;
      })
    );
  };
}
