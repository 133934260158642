import { SpeedIndicator } from '../models/speed-indicator';

export const SPEED_INDICATOR_OPTIONS_CONST = [
  {
    code: 'F',
    description: {
      en: 'up  to 80 km/h',
      fr: 'à 80 km/h',
      nl: 't/m 80 km/u',
      de: 'bis 80 km/u',
    },
  },
  {
    code: 'G',
    description: {
      en: 'up  to 90 km/h',
      fr: 'à 90 km/h',
      nl: 't/m 90 km/u',
      de: 'bis 90 km/u',
    },
  },
  {
    code: 'H',
    description: {
      en: 'up  to 210 km/h',
      fr: 'à 210 km/h',
      nl: 't/m 210 km/u',
      de: 'bis 210 km/u',
    },
  },
  {
    code: 'J',
    description: {
      en: 'up  to 100 km/h',
      fr: 'à 100 km/h',
      nl: 't/m 100 km/u',
      de: 'bis 100 km/u',
    },
  },
  {
    code: 'K',
    description: {
      en: 'up  to 110 km/h',
      fr: 'à 110 km/h',
      nl: 't/m 110 km/u',
      de: 'bis 110 km/u',
    },
  },
  {
    code: 'L',
    description: {
      en: 'up  to 120 km/h',
      fr: 'à 120 km/h',
      nl: 't/m 120 km/u',
      de: 'bis 120 km/u',
    },
  },
  {
    code: 'M',
    description: {
      en: 'up  to 130 km/h',
      fr: 'à 130 km/h',
      nl: 't/m 130 km/u',
      de: 'bis 130 km/u',
    },
  },
  {
    code: 'N',
    description: {
      en: 'up  to 140 km/h',
      fr: 'à 140 km/h',
      nl: 't/m 140 km/u',
      de: 'bis 140 km/u',
    },
  },
  {
    code: 'P',
    description: {
      en: 'up  to 150 km/h',
      fr: 'à 150 km/h',
      nl: 't/m 150 km/u',
      de: 'bis 150 km/u',
    },
  },
  {
    code: 'Q',
    description: {
      en: 'up  to 160 km/h',
      fr: 'à 160 km/h',
      nl: 't/m 160 km/u',
      de: 'bis 160 km/u',
    },
  },
  {
    code: 'R',
    description: {
      en: 'up  to 170 km/h',
      fr: 'à 170 km/h',
      nl: 't/m 170 km/u',
      de: 'bis 170 km/u',
    },
  },
  {
    code: 'S',
    description: {
      en: 'up  to 180 km/h',
      fr: 'à 180 km/h',
      nl: 't/m 180 km/u',
      de: 'bis 180 km/u',
    },
  },
  {
    code: 'T',
    description: {
      en: 'up  to 190 km/h',
      fr: 'à 190 km/h',
      nl: 't/m 190 km/u',
      de: 'bis 190 km/u',
    },
  },
  {
    code: 'U',
    description: {
      en: 'up  to 200 km/h',
      fr: 'à 200 km/h',
      nl: 't/m 200 km/u',
      de: 'bis 200 km/u',
    },
  },
  {
    code: 'V',
    description: {
      en: 'up  to 240 km/h',
      fr: 'à 240 km/h',
      nl: 't/m 240 km/u',
      de: 'bis 240 km/u',
    },
  },
  {
    code: 'W',
    description: {
      en: 'up  to 270 km/h',
      fr: 'à 270 km/h',
      nl: 't/m 270 km/u',
      de: 'bis 270 km/u',
    },
  },
  {
    code: 'Y',
    description: {
      en: 'up  to 300 km/h',
      fr: 'à 300 km/h',
      nl: 't/m 300 km/u',
      de: 'bis 300 km/u',
    },
  },
  {
    code: 'Z',
    description: {
      en: '> 240 km/h',
      fr: '> 240 km/h',
      nl: '> 240 km/u',
      de: '> 240 km/u',
    },
  },
] as const;

export const SPEED_INDICATOR_OPTIONS = SPEED_INDICATOR_OPTIONS_CONST as unknown as SpeedIndicator[];
