import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

declare module '@angular/forms' {
  export namespace Validators {
    // @ts-ignore
    let email: (control: AbstractControl) => ValidationErrors | null;
  }
}

const EMAIL_REGEX = /^([\w+-]+\.)*(\w|-|\+)+@([\w-]+\.)+\w{2,}$/;

function isEmptyInputValue(value: unknown): boolean {
  /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
  return value == null ||
    ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

/**
 * Validator that requires the control's value pass an email validation test.
 * Overriding default angular `Validators.email`
 */
Validators.email = function emailValidator(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }

  return EMAIL_REGEX.test(control.value as string) ? null : { email: true };
};
