import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, takeWhile } from 'rxjs/operators';
import { SKIP_RS_MESSAGE_HANDLER_ERRORS_CONTEXT } from '../../../modules/rs-core/interceptors/rs-http-error-interceptor/http-context-tokens/skip-rs-message-handler-errors';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  public apiUrl = 'http://localhost:3300/upload';

  public constructor(
    private http: HttpClient
  ) {
  }

  /** Http POST to Upload document
   * Upload only one document, so for multi uploads, call multiple times,
   * so you can have the progress event per file uploading
   *
   * @param url string
   * @param formData: FormData
   *
   * @return progress:Observable<number>
   */
  public upload(url: string, formData: FormData): Observable<HttpEvent<Object>> {

    return this.http.post(
      url,
      formData,
      {
        reportProgress: true,
        observe: 'events',
        context: SKIP_RS_MESSAGE_HANDLER_ERRORS_CONTEXT,
      }
    ).pipe(
      takeWhile((event) => {
        return !!event;
      }),
      catchError((err: HttpErrorResponse): Observable<never> => {
        return this.handleError(err);
      })
    );
  }

  /** Handles error and display them for developers
   *
   * @param error :HttpErrorResponse
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) { // eslint-disable-line no-undef
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(() => error);
  }
}
