import { Pipe, PipeTransform } from '@angular/core';
import { AuditTrailUser } from '../../models/audit';

@Pipe({
  name: 'rsCreationEditionUser',
  standalone: true
})
export class CreationEditionUserPipe implements PipeTransform {

  public transform(user: string | AuditTrailUser | null): string {
    return typeof user === 'string' ? user : user?.fullName ?? user?.rsUserNumber ?? '';
  }

}
