<footer class="global container">
  <section class="row section-footer align-items-stretch">
    <div class="left-area col-12 col-sm-12 col-lg-8 text-center text-lg-start">
      <span class="copyright">{{ copyrightYear }}
        <mat-icon>copyright</mat-icon>
        {{ companyName }}
      </span>
      <ul>
        <li>
          <a
            [href]="currentDisclaimerURL"
            target="_blank"
          >{{ disclaimerKey | translate }}</a>
        </li>
        <li>
          <a
            [href]="currentCookiesURL"
            target="_blank"
          >{{ cookiesKey | translate }}</a>
        </li>
      </ul>
    </div>

    <div class="right-area col-12 col-sm-12 col-lg-4 text-center text-lg-end">
      <button
        [matMenuTriggerFor]="aboveMenu"
        class="btn-lang-switcher"
        data-testid='footer-lang-switcher-btn'
        mat-button
      >
        {{ currentLanguageLongString }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <mat-menu
        #aboveMenu="matMenu"
        class="rs-language-menu"
        yPosition="above"
      >
        <button
          (click)="setLanguage(language);"
          *ngFor="let language of displayLanguages"
          mat-menu-item
        >
          {{ language.long }}
        </button>
      </mat-menu>

      <a
        [href]="currentSupportURL"
        class="help"
        data-testid='footer-need-help-btn'
        target="_blank"
      >
        <mat-icon>help_outline</mat-icon>
        Support Center
      </a>
    </div>
  </section>
</footer>