import { NavigationExtras } from '@angular/router';
import { MatBadgePosition, MatBadgeSize } from '@angular/material/badge';
import { Environment } from '../../../models/custom-types';

/** MenuItem class used by the [RsSubHeaderComponent]{@link SubheaderComponent}
 *
 * @param name: string;
 *
 * @param path?: string  - Path to go to when clicked on the item Will be passed to an [Angular routerLink directive]{@link https://angular.io/api/router/RouterLink}
 *
 * @param onClick?: Function function to execute on click event
 *
 * @param navigationExtras?: NavigationExtras = {}; Router navigations extras [Angular router NavigationExtras]{@link https://angular.io/api/router/NavigationExtras}
 *
 * @param hide?: boolean = false; Whether this item should be displayed
 *
 * @param selected?: boolean = false; Whether this item is currently selected
 *
 * @param rightSide?: boolean = false; Whether this item should be shown on the right side of the subheader
 *
 * @param id?: string  = ''; The [HTML id attribute]{@link https://www.w3schools.com/html/html_id.asp} for the given menu item
 *
 * @param badge?: {
 *       content: number;
 *       displayContent: boolean;
 *       colorMap?: string;
 *       matBadgePosition?: MatBadgePosition;
 *       matBadgeOverlap?: boolean;
 *       matBadgeSize?: MatBadgeSize;
 *     }; Add material badge around menu item
 *
 * @param rsIfEnvironment?: environments[];  = ''; Will display sub menu item on only on specified environments.
 */
export class SubheaderMenuItems {
  /** Title of the item, what's seen by the user in the subheader
   *
   * Can be an ngx-translate translation key and will automatically be translated */
  public name!: string;

  /** Path to go to when clicked on the item
   *
   * Will be passed to an [Angular routerLink directive]{@link https://angular.io/api/router/RouterLink}*/
  public path?: string;

  /** function to execute on click event */
  public onClick?: Function;

  /** Router navigations extras
   * [Angular router NavigationExtras]{@link https://angular.io/api/router/NavigationExtras}
   */
  public navigationExtras?: NavigationExtras = {};

  /** Whether this item should be displayed
   *
   * Is optional, default is false
   */
  public hide?: boolean = false;

  /** Whether this item is currently selected
   *
   * Is optional, default is false */
  public selected?: boolean = false;

  /** Whether this item should be shown on the left side menu of the subheader
   *
   * Is optional, default is false
   **/
  public alignLeft?: boolean = false;

  /** The [HTML id attribute]{@link https://www.w3schools.com/html/html_id.asp} for the given menu item */
  public id?: string = '';

  /** Add material badge around menu item
   *
   * @optional
   *
   * @param {string} content string (content to display on the badge)
   * @param {boolean} displayContent boolean (if false will display badge with content hidden)
   * @param {string} colorMap (background color of the badge. Must be a color name existing in the shared lib color map)
   * @param {MatBadgePosition} matBadgePosition
   * @param {boolean} matBadgeOverlap
   * @param {MatBadgeSize} matBadgeSize
   */
  public badge?: {
    content: number;
    displayContent: boolean;
    colorMap?: string;
    matBadgePosition?: MatBadgePosition;
    matBadgeOverlap?: boolean;
    matBadgeSize?: MatBadgeSize;
  } = {
      content: 0,
      displayContent: false,
      colorMap: 'rs-color--success',
      matBadgePosition: 'above after',
      matBadgeOverlap: false,
      matBadgeSize: 'small'
    };

  /** Will hide sub menu item on following environments. Never hides on local dev */
  public rsIfEnvironment?: Environment[] = []
}
