<main
  [attr.data-cy]='"rs-message-handler-" + data?.type'
  [style.display]="state().value === 'inactive' ? 'none' : ''"
>
  <ng-container *ngIf="data?.type === 'success'">
    <mat-icon class="me-20px">thumb_up</mat-icon>
  </ng-container>

  <div class='rs-message-handler-msg'>

    <span
      *ngIf="msg"
      [innerHTML]="msg"
    ></span>

    <!-- BE array list of errors -->
    <ng-container *ngIf="data?.type == 'errors-array'">
      <ul
        [class.mt-10px]='msg'
        [class.no-list-type]="data?.listOfErrors?.length == 1"
        class="be-errors-list"
      >
        <li *ngFor="let error of data?.listOfErrors">
          {{ ((error.translations || {}) | rsTranslate) || ('ERROR_MSG.GLOBAL' | translate) }}
          <div
            *ngIf='error.errorId'
            class='error-id rs-font-size-11px'
          >
            {{ 'ERROR_MSG.ERROR_ID' | translate }}: {{ error.errorId }}
          </div>
        </li>
      </ul>
    </ng-container>
  </div>

  <button
    (click)="remove()"
    *ngIf="options.closeButton"
    class='rs-msg-handler-close-btn'
    mat-icon-button
  >
    <mat-icon>close</mat-icon>
  </button>
</main>

<div *ngIf="options.progressBar">
  <div
    [style.width]="width + '%'"
    class="toast-progress"
  ></div>
</div>