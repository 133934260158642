<nav
  [ngClass]="{'menu-open': menuHamburgerOpen}"
  class="rs-sub-header container row g-0 align-items-center"
>
  <!-- Title and hamburger header when resized -->
  <div class="column col-12 col-lg-auto p-10px p-md-0 text-center text-lg-left">
    <div class="hamburger-header row justify-content-start justify-content-md-center justify-content-lg-start g-0 align-items-center">

      <div class="column col-10 col-sm-auto align-self-center">
        <div class="text-start rs-sub-header-title">
          <a
            (click)="backLinkOnClickEvent()"
            *ngIf="backLinkOptions?.backRouterLink?.length"
            class="rs-sub-header-back-button"
            data-testid="subheader-back-arrow-icon"
          >
            <mat-icon>arrow_back</mat-icon>
          </a>

          <ng-content></ng-content>
        </div>
      </div>

      <div class="column sub-component col-12 col-sm-auto d-lg-none pt-15px pt-sm-0 mx-15px order-3 order-sm-2">
        <ng-container
          *ngIf="menuComponent && menuComponent.alignLeft"
          [ngTemplateOutlet]="$any(menuComponent.component)"
        >
        </ng-container>
      </div>

      <div class="column sub-component col-12 col-sm-auto d-flex d-lg-none pt-15px pt-sm-0 mx-15px order-4 order-sm-3">
        <ng-container
          *ngIf="menuComponent && !menuComponent.alignLeft"
          [ngTemplateOutlet]="$any(menuComponent.component)"
        >
        </ng-container>
      </div>

      <div
        *ngIf="_rightMenuItems.length > 0  || _leftMenuItems.length > 0"
        class="column col-2 col-sm d-md-none justify-content-end text-end order-2 order-sm-4"
      >
        <button
          (click)="toggleHamburgerMenu()"
          [ngClass]="{'open': menuHamburgerOpen}"
          class="btn-hamburger d-md-none d-inline-block"
          mat-button
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </div>

  <div class="column col-12 col-lg-auto d-none d-md-flex  p-10px p-md-0 align-self-stretch justify-content-center justify-content-lg-start text-center text-lg-left">
    <div class="row g-0">
      <div class="col-auto d-none d-lg-flex mx-15px">
        <ng-container
          *ngIf="menuComponent && menuComponent.alignLeft"
          [ngTemplateOutlet]="$any(menuComponent.component)"
        >
        </ng-container>
      </div>

      <div class="col-auto">
        <ng-template [ngTemplateOutlet]="leftNav"></ng-template>
      </div>
    </div>
  </div>

  <div class="column col-12 col-lg d-none d-md-flex p-10px p-md-0 align-self-stretch justify-content-center justify-content-lg-end text-center text-lg-right">
    <div class="row g-0">
      <div class="col-auto d-none d-lg-flex mx-15px">
        <ng-container
          *ngIf="menuComponent && !menuComponent.alignLeft"
          [ngTemplateOutlet]="$any(menuComponent.component)"
        >
        </ng-container>
      </div>

      <div class="col-auto">
        <ng-template [ngTemplateOutlet]="rightNav"></ng-template>
      </div>
    </div>
  </div>
</nav>

<nav
  [ngClass]="{'open': menuHamburgerOpen}"
  class="nav-hamburger d-md-none d-block"
>
  <div class="nav-hamburger-container">
    <ng-template [ngTemplateOutlet]="leftNav"></ng-template>
    <ng-template [ngTemplateOutlet]="rightNav"></ng-template>
  </div>
</nav>

<ng-template #rightNav>
  <button
    (click)="onClick(menuItem)"
    *ngFor="let menuItem of _rightMenuItems"
    [class.selected]="menuItem.selected"
    [disabled]="menuItem.selected"
    [hidden]="menuItem.hide"
    [id]="menuItem.id"
    class="subheader-nav-item"
  >
    <span
      *ngIf="menuItem.badge"
      [class.hide-badge-content]="!menuItem.badge.displayContent"
      [matBadgeOverlap]="menuItem.badge.matBadgeOverlap || false"
      [matBadgePosition]="menuItem.badge.matBadgePosition || 'after'"
      [matBadgeSize]="menuItem.badge.matBadgeSize || 'small'"
      [matBadge]="menuItem.badge.content"
      class="mat-badge-container mat-badge-rs-bg-color-{{ menuItem.badge.colorMap || 'color--error' }}"
    >
      {{ menuItem.name | translate }}
    </span>

    <span *ngIf="!menuItem.badge">
      {{ menuItem.name | translate }}
    </span>
  </button>
</ng-template>

<ng-template #leftNav>
  <button
    (click)="onClick(menuItem)"
    *ngFor="let menuItem of _leftMenuItems"
    [class.selected]="menuItem.selected"
    [disabled]="menuItem.selected"
    [hidden]="menuItem.hide"
    [id]="menuItem.id"
    class="subheader-nav-item"
  >
    <span
      *ngIf="menuItem.badge"
      [class.hide-badge-content]="!menuItem.badge.displayContent"
      [matBadgeOverlap]="menuItem.badge.matBadgeOverlap || false"
      [matBadgePosition]="menuItem.badge.matBadgePosition || 'after'"
      [matBadgeSize]="menuItem.badge.matBadgeSize || 'small'"
      [matBadge]="menuItem.badge.content"
      class="mat-badge-container mat-badge-rs-bg-color-{{ menuItem.badge.colorMap || 'color--error' }}"
    >
      {{ menuItem.name | translate }}
    </span>

    <span *ngIf="!menuItem.badge">
      {{ menuItem.name | translate }}
    </span>
  </button>
</ng-template>