import { Environment } from '../../../models';

export interface EnvironmentConfig {
    production: boolean;
    apiUrl: string;
    useMockedServices: boolean;
    enableSentryMonitoring: boolean;
    sentryReleaseName: string;
    /* Used for sentry and rsIfEnvironment **/
  environment: Environment;
    topBarEnvironment: 'tst' | 'acc' | 'prd' | 'dev';
    weblateEnvironment: 'tst' | 'acc' | 'prod';
    topBarCdn: string;
    authUrl: string;
    version?: number;
}
