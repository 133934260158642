<div class="rs-date-picker-range row align-items-center g-0">

  <!-- FROM -->
  <div class="col-md-6 pe-md-2">
    <mat-form-field
      (click)="datePickerStartDate.open(); controlDirective.control!.markAsTouched()"
      (onRsClearFormFieldButtonClicked)="deleteStartDate($event)"
      [attr.data-cy]="startDateDataCy"
      [attr.data-testid]="startDateDataCy"
      [ngClass]="{ 'invalid-date-picker': validators?.required && controlDirective.control!.invalid && controlDirective.control!.touched }"
      class="rs-datepicker"
      id="startDate"
      rsAddClearFormFieldButton
    >
      <mat-label>
        {{ 'FROM' | translate }}
        <span *ngIf="this.validators?.required">*</span>
      </mat-label>

      <input
        (dateChange)="startDateChange($event.value)"
        [disabled]="startDateDisabled"
        [matDatepicker]="datePickerStartDate"
        [min]="minStartDate ? minStartDate : null"
        [value]="value.startDate"
        autocomplete="off"
        matInput
        readonly
      >
      <mat-datepicker-toggle
        [class.rs-datepicker-disabled]="startDateDisabled"
        [disabled]="startDateDisabled"
        [for]="datePickerStartDate"
        matSuffix
      ></mat-datepicker-toggle>

      <mat-datepicker
        #datePickerStartDate
        [class.rs-datepicker-disabled]="startDateDisabled"
        [disabled]="startDateDisabled"
        restoreFocus="false"
      ></mat-datepicker>
    </mat-form-field>
  </div>

  <!-- TO -->
  <div class="col-md-6">
    <mat-form-field
      (click)="datePickerEndDate.open(); isEndDateTouched=true"
      (onRsClearFormFieldButtonClicked)="deleteEndDate($event)"
      [attr.data-cy]="endDateDataCy"
      [attr.data-testid]="endDateDataCy"
      [class.rs-datepicker-readonly]="!value.startDate"
      [ngClass]="{ 'invalid-date-picker': endDateRequired && isEndDateTouched && !value.endDate && value.startDate }"
      class="rs-datepicker rs-daterange-end-date"
      id="endDate"
      rsAddClearFormFieldButton
    >

      <mat-label>{{ 'TO' | translate }} <span *ngIf="endDateRequired">*</span></mat-label>

      <input
        (dateChange)="endDateChange($any($event.value))"
        [disabled]="!value.startDate"
        [matDatepicker]="$any(datePickerEndDate)"
        [min]="$any(minEndDate)"
        [value]="$any(value.endDate)"
        autocomplete="off"
        matInput
        readonly
      >

      <mat-datepicker-toggle
        [for]="$any(datePickerEndDate)"
        matSuffix
      ></mat-datepicker-toggle>

      <mat-datepicker
        #datePickerEndDate
        [disabled]="!value.startDate"
        [startAt]="value.startDate"
        restoreFocus="false"
      ></mat-datepicker>
    </mat-form-field>
  </div>
</div>