/**
 * @deprecated Please @Input decorator and mark it as required
 * ex: @Input({ required: true })
 * @param validatorsList
 */
export function inputValidator(validatorsList: string[]): Function {

  return (target: unknown, propertyKey: string): void => {

    const validators = {
      // validUrl: function(target, propertyKey: string, newVal: string): void {
      //   const regex = /(^|\s)(((http|ftp|https)?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;

      //   /** Whether the new value is an url */
      //   const isValid = regex.test(newVal);

      //   if ( !isValid ) {
      //     console.error(
      //       target.constructor.name +
      //       `: @Input '${propertyKey}' is not a valid url`
      //     );
      //     // throw new Error(`: @Input '${prop}' is not a valid url`);
      //   }
      // },
      required: function (instance: string, propertyKey: string, newVal: string): void {
        /** Whether the new value is not empty */
        if (newVal == null || newVal === '') {

          console.error(instance.constructor.name +
            `: @Input '${propertyKey}' is required, but was not provided by the following instance `, instance);
        }
      }
    };

    // Create map to store values associated to a instance
    const values = new WeakMap(); // eslint-disable-line no-undef

    /** Define property on the target with only a `setter` because we don't
     * want to read from the prototype but instead from the instance.
     * Once the value of the property is set for the first time we define
     * a property with a `getter` and `setter` on the instance.
     */
    Object.defineProperty(target, propertyKey, {
      set(newValue: string): string {
        /** This `setter` gets called once per new instance, and only the
         * first time we set the value of the target property.
         * Here we have access to the instance `this`, so we define
         * a property with the same name on the instance.
         */
        Object.defineProperty(this, propertyKey, {
          get(): string {
            /** This `getter` gets called every time we read the instance property.
             * We simply look up the instance in our map and return its value.
             */
            return values.get(this as object);
          },
          set(newValue: string): void {
            /** This `setter` is called every time we set the value of the
             * property on the instance.
             */
            validatorsList.forEach((validatorName): void => {
              validators[validatorName as 'required'](this as string, propertyKey, newValue);
            });

            values.set(this as object, newValue);
          }
        });

        /** Finally we set the value of property on the instance.
         * This will trigger the `setter` on the instance that we defined above.
         */
        return this[propertyKey] = newValue;
      }
    });
  };
}
