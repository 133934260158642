import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'rs-active-inactive-icon[condition]',
  templateUrl: './rs-active-inactive-icon.component.html',
  styleUrls: ['./rs-active-inactive-icon.component.scss'],
  imports: [MatIconModule],
  standalone: true
})
export class RsActiveInactiveIconComponent implements OnInit {
  @Input() public condition!: boolean;

  public iconDetails = {
    dataTestId: 'rs-inactive-icon',
    class: 'rs-color--grey--tuna',
    iconName: 'highlight_off'
  }

  public ngOnInit(): void {
    if (this.condition) {
      this.iconDetails = {
        dataTestId: 'rs-active-icon',
        class: 'rs-color--green--granny-smith',
        iconName: 'check_circle'
      }
    }
  }


}
