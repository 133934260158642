import { RsSectionLoaderDirective } from './directives/section-loader/rs-section-loader.directive';
import { SectionLoaderComponent } from './components/section-loader/section-loader.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RsFooterComponent } from './components/footer/rs-footer.component';
import { RsTopBarMenuComponent } from './components/topbar/rs-top-bar-menu.component';
import { RsSubHeaderComponent } from './components/subheader/rs-sub-header.component';
import { TableColumnsReordererComponent } from './components/table-columns-reorderer/table-columns-reorderer.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RsAlphanumericInputDirective } from './directives/alphanumeric-input/rs-alphanumeric-input.directive';
import {
  RsTwoDigitDecimaNumberDirective
} from './directives/two-digit-decima-number/rs-two-digit-decima-number.directive';
import { SimpleNgForDirective } from './directives/simple-ngfor/simple-ng-for.directive';
import { RsSpinnerButtonDirective } from './directives/spinner-button/rs-spinner-button.directive';
import { CustomMaterialModule } from './modules/custom-material-module.module';
import { VatInputDirective } from './directives/vat-input/vat-input.directive';
import { InputTrimDirective } from './directives/trim.directive';
import { RsMatStepperComponent } from './components/rs-mat-stepper/rs-mat-stepper.component';
import { RsNgVarDirective } from './directives/rs-ng-var/rs-ng-var.directive';
import { RsInputPrefixDirective } from './directives/input-prefix/input-prefix.directive';
import { RsUppercaseInputDirective } from './directives/uppercase-input/rs-uppercase-input.directive';
import { RsSharedModule } from './shared/shared.module';
import { RsIntegerInputDirective } from './directives/rs-integer-input/rs-integer-input.directive';
import { RsNumberMaskInputDirective } from './directives/rs-number-mask-input/rs-number-mask-input.directive';
import { RsMessageHandlerModule } from './components/rs-messages-handler/rs-message-handler.module';
import { RsIfEnvironmentDirective } from './directives/rs-if-environment/rs-if-environment.directive';
import './3rd-party-libraries/module-augmentation';
import {
  RsDynamicMatAutocompleteModule
} from './components/rs-dynamic-mat-autocomplete/rs-dynamic-mat-autocomplete.module';
import { RsActiveInactiveIconComponent } from './components/rs-active-inactive-icon/rs-active-inactive-icon.component';
import { RsNullDashPipe } from './pipes/rs-null-dash/rs-null-dash.pipe';
import { RsDateRangeComponent } from './components/date-range-control/components/rs-date-range.component';
import { RsFileUploaderComponent } from './components/file-uploader/rs-file-uploader.component';
import { RsLoaderComponent } from './components/rs-loader/components/rs-loader.component';
import { RsAuditTrailComponent } from './components/rs-audit-trail/rs-audit-trail.component';

/** @deprecated Usage will change, please read description below!!!
 *
 * @description What!!! How should we use it now ???
 *
 * - Will be used only to import/export real shared modules like CommonModule, TranslateModule.forChild (Those will be shared only in rentaNgxModule)
 * - For other modules like Material one's, please ONLY import the ones you need where you need them!
 * - For shared components, please create standalone ones and import it only where needed
 * - Same for shared directives, and pipes, please create standalone ones and import it only where needed
 **/
@NgModule({
  declarations: [
    SimpleNgForDirective,
    SectionLoaderComponent,
    InputTrimDirective,
    RsNgVarDirective,
    RsIfEnvironmentDirective
  ],
  imports: [
    RsTwoDigitDecimaNumberDirective,
    RsIntegerInputDirective,
    RsActiveInactiveIconComponent,
    TableColumnsReordererComponent,
    RsLoaderComponent,
    RsUppercaseInputDirective,
    RsSectionLoaderDirective,
    RsNumberMaskInputDirective,
    RsAlphanumericInputDirective,
    RsFooterComponent,
    RsSpinnerButtonDirective,
    RsTopBarMenuComponent,
    RsSubHeaderComponent,
    CommonModule,
    RouterModule,
    TranslateModule,
    RsNullDashPipe,
    DragDropModule,
    CustomMaterialModule,
    MatProgressSpinnerModule,
    RsFileUploaderComponent,
    RsDateRangeComponent,
    RsSharedModule,
    RsMessageHandlerModule,
    VatInputDirective,
    RsDynamicMatAutocompleteModule,
    TranslateModule.forChild({
      extend: true
    }),
    RsAuditTrailComponent,
    RsInputPrefixDirective,
    RsMatStepperComponent
  ],
  exports: [
    RsLoaderComponent,
    RsNullDashPipe,
    RsFooterComponent,
    RsSpinnerButtonDirective,
    RsTopBarMenuComponent,
    RsSubHeaderComponent,
    TableColumnsReordererComponent,
    RsMatStepperComponent,
    RsFileUploaderComponent,
    RsAlphanumericInputDirective,
    RsTwoDigitDecimaNumberDirective,
    SimpleNgForDirective,
    SectionLoaderComponent,
    RsSectionLoaderDirective,
    VatInputDirective,
    InputTrimDirective,
    RsNgVarDirective,
    RsInputPrefixDirective,
    RsUppercaseInputDirective,
    RsIntegerInputDirective,
    RsNumberMaskInputDirective,
    RsDateRangeComponent,
    RsSharedModule,
    RsIfEnvironmentDirective,
    RsDynamicMatAutocompleteModule,
    RsActiveInactiveIconComponent,
    RsAuditTrailComponent,
    TranslateModule
  ]
})
export class RentaNgxLibModule {
}
