import { AbstractControl, Validators } from '@angular/forms';

type RsVatValidatorError = {
  vat: { value: string, valid: false, msg?: string }
} | null;

const
  BE_VAT_REGEX = /^BE[0-9]{10}$/,
  NL_VAT_REGEX = /^NL[0-9]{9}[A-Z]{1}[0-9]{2}$/,
  LU_VAT_REGEX = /^LU[0-9]{8}$/,
  DE_VAT_REGEX = /^DE[0-9]{9}$/,
  FR_VAT_REGEX = /^FR([A-Z]|[0-9]){2}[0-9]{9}$/;

declare module '@angular/forms' {
  export namespace Validators {

    /** ***Validates a VAT number based on its country code.***
     * ***
     * @description This function supports validation for Belgium (BE), France (FR), Netherlands (NL), Luxembourg (LU), and Germany (DE).
     *
     * @param {string} vatNumber The VAT number to be validated.
     * @returns {null|RsVatValidatorError} Null if the VAT number is valid, otherwise an error object.
     *   The error object has a property `vat` with a nested object containing the `value` (the VAT number)
     *   and a `valid` property set to `false`. Additionally, for unsupported countries or invalid formats,
     *   an `msg` property might be included with an informative message.
     */
    let rsVatValidator: (control: AbstractControl) => RsVatValidatorError;
  }
}

Validators.rsVatValidator = (control: AbstractControl<string>): RsVatValidatorError => {
  return control.rsHasValue() ? validateVat(control.value) : null;
}

function validateVat(vatNumber: string): RsVatValidatorError {
  const countryIsoCode = vatNumber.substring(0, 2).toUpperCase();

  let
    verificationNumber: number | null = null,
    lastTwoDigits: unknown, // Real type is BigInteger !!
    isVatNumberValid: boolean;


  switch (countryIsoCode) {
    case 'BE':
      verificationNumber = 97 - Number(vatNumber.substring(2, 10)) % 97;
      lastTwoDigits = Number(vatNumber.substring(10));
      isVatNumberValid = BE_VAT_REGEX.test(vatNumber) && lastTwoDigits == verificationNumber;

      return isVatNumberValid ? null : { vat: { value: vatNumber, valid: false } };

    case 'FR':
      verificationNumber = Number((12 + (3 * (Number(vatNumber.substring(4, 13)) % 97)))) % 97;
      lastTwoDigits = Number(vatNumber.substring(2, 4));
      isVatNumberValid = FR_VAT_REGEX.test(vatNumber) && lastTwoDigits == verificationNumber;

      return isVatNumberValid ? null : { vat: { value: vatNumber, valid: false } };

    case 'NL':
      return NL_VAT_REGEX.test(vatNumber) ? null : { vat: { value: vatNumber, valid: false } };

    case 'LU':
      return LU_VAT_REGEX.test(vatNumber) ? null : { vat: { value: vatNumber, valid: false } };

    case 'DE':
      return DE_VAT_REGEX.test(vatNumber) ? null : { vat: { value: vatNumber, valid: false } };

    default:
      return { vat: { value: vatNumber, valid: false, msg: 'No country-iso-code or No validation for this country-iso-code yet' } };
  }
}
