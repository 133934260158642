import { MatPaginatorIntl } from '@angular/material/paginator';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { CustomMatPaginatorIntlService } from '_core/services/material/custom-mat-paginator-intl';


export const coreServiceProviders = [
  JwtHelperService,
  CookieService,
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService }
];
