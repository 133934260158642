import { Directive, OnDestroy } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Directive()
export abstract class PrintBlobAbstract implements OnDestroy {
  private printIframe?: HTMLIFrameElement;

  protected constructor(
    public iframeId: string
  ) {
  }

  public ngOnDestroy(): void {
    document.querySelector(`#${this.iframeId}`)?.remove();
  }

  public printFile(
    data: BlobPart,
    requestHeaders: HttpHeaders
  ): void {
    const
      blob = new Blob([data], { type: requestHeaders.get('Content-Type')! }),
      url = URL.createObjectURL(blob),
      existingPrintIframe = document.querySelector(`#${this.iframeId}`) as HTMLIFrameElement;

    if (!existingPrintIframe) {
      this.printIframe = document.createElement('iframe');
      document.body.appendChild(this.printIframe);
      this.printIframe.style.display = 'none';
      this.printIframe.id = 'print-registration-broker-iframe';
    } else {
      this.printIframe = existingPrintIframe;
    }

    this.printIframe.src = url;

    this.printIframe.onload = (): void => {
      this.printIframe!.contentWindow!.focus();
      this.printIframe!.contentWindow!.print();
    };
  }
}
