import { Directive, ElementRef, HostListener, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { RsUtils } from '../../utils/utils';

/** Transform input into integer only with regex and transforming input type to number
 *
 * Therefore, no decimals will be allowed, prevent pasting 'e', '-'
 *
 * if maxlength needed please use rsNumberMaskInput
 *
 * Usage Example
 *
 * ```html
 <input rsIntegerInput></input>
 ```
 *
 */

@Directive({
  selector: '[rsIntegerInput]',
  standalone: true
})
export class RsIntegerInputDirective implements OnInit, ControlValueAccessor {
  public inputElement: ElementRef;

  public constructor(
    public el: ElementRef,
    @Self() @Optional() public controlDirective: NgControl
  ) {
    this.inputElement = el;
    this.inputElement.nativeElement.setAttribute('autocomplete', 'off');
    this.inputElement.nativeElement.setAttribute('type', 'number');
  }

  public ngOnInit(): void {
    this.setValidationRegex();
  }

  public registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.onChange(value);
    this.controlDirective.control?.patchValue(value);
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    /** Why implement keydown ? To return correct value onValueChange */
    const
      regex = /^(0|[1-9][0-9]*)$/g,
      isAllowedKey = regex.test(event.key),
      htmlInputElement = this.inputElement.nativeElement as HTMLInputElement;

    if (!isAllowedKey && !RsUtils.isAllowableKeyOrKeyCombination(event)) {
      event.preventDefault();
    } else if (isAllowedKey && htmlInputElement.value === '0') {
      htmlInputElement.value = '';
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    /** Why implement paste ? To return correct value onValueChange */
    const
      regex = /^(0|[1-9][0-9]*)$/g,
      inputValue = event.clipboardData?.getData('text/plain'),
      isAllowedValue = inputValue !== undefined ? regex.test(inputValue) : false,
      htmlInputElement = this.inputElement.nativeElement as HTMLInputElement;

    if (!isAllowedValue) {
      event.preventDefault();
    } else if (htmlInputElement.value === '0') {
      htmlInputElement.value = '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-empty-function
  private onChange = (_param: string): void => {
  };

  // eslint-disable-next-line no-empty-function
  private onTouched = (): void => {
  };

  private setValidationRegex(): void {
    const validationRegex = new RegExp('^(0|[1-9][0-9]*)$');
    // Set control's pattern validator and add it to eventual ones
    this.controlDirective.control?.addValidators(Validators.pattern(validationRegex));
    this.controlDirective.control?.updateValueAndValidity();
  }
}
