import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RsDynamicMatAutocompleteComponent } from './rs-dynamic-mat-autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RsSharedModule } from '../../shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [RsDynamicMatAutocompleteComponent],
  exports: [RsDynamicMatAutocompleteComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    RsSharedModule
  ]
})
export class RsDynamicMatAutocompleteModule {
}
