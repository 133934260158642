<section class="file-uploader-container pt-1px ">

  <!-- UPLOADER -->
  <section
    data-testid="rs-file-dropzone"
    (dropped)="onDrop($event)"
    (hovered)="toggleHover($event)"
    [class.disabled]="disableUploader"
    [class.hovering]="isHovering && !disableUploader"
    class="rs-dropzone position-relative"
    rsDropzone
  >
    <div [ngClass]="{'file-uploader-disabler': disableUploader}"></div>

    <div class='content-area'>
      <mat-icon class='cloud-icon'>cloud_upload</mat-icon>
      <p>
        <span class='rs-font--primary-regular'>{{ "FILE_UPLOAD_COMPONENT.DRAG_AND_DROP" | translate }}</span>
        {{ "FILE_UPLOAD_COMPONENT.YOUR_FILES_HERE_OR" | translate }}
        <label
          [for]="'file-input-' + inputFileUid"
          class="file-label rs-font--primary-regular"
        >
          {{ "FILE_UPLOAD_COMPONENT.BROWSE" | translate }}
        </label>
        {{ "FILE_UPLOAD_COMPONENT.FILES" | translate }}
      </p>
    </div>

    <input
      #fileInput
      (change)="onDrop(fileInput.files)"
      [id]="'file-input-' + inputFileUid"
      [multiple]="allowMultiUpload"
      class="file-input"
      type="file"
    >
  </section>

  <section class="rs-dropzone-helpers row g-0 w-100">
    <div class='col-6'>
      <ul
        *ngIf="acceptedFileTypes && acceptedFileTypes.length > 0"
        [attr.data-title]="'FILE_UPLOAD_COMPONENT.ACCEPTED_FILE_TYPES' | translate"
        class="ct-accepted-file-types"
      >
        <li
          *ngFor="let file of (acceptedFileTypes ?? [])"
          [attr.data-mime-type]="stripMimeTypes(file)"
        >
          {{ enumMimeTypes[file] || stripMimeTypes(file) }}
        </li>
      </ul>
    </div>

    <div class='col-6 text-end'>
      <p *ngIf="maxFiles">
        {{ 'FILE_UPLOAD_COMPONENT.MAX_FILES' | translate }}:
        <span>{{ this.maxFiles }}</span>
      </p>
      <p
        class="ct-max-files-size"
        *ngIf="allowMultiUpload && maxSizePerFile"
      >
        {{ 'FILE_UPLOAD_COMPONENT.MAX_FILE_SIZE' | translate }}
        :
        <span>{{ maxFileSizePerFileDisplay }}</span>
      </p>
      <p>
        {{ (allowMultiUpload ? 'FILE_UPLOAD_COMPONENT.MAX_TOTAL_SIZE' : 'FILE_UPLOAD_COMPONENT.MAX_FILE_SIZE') | translate }}
        :
        <span>{{ maxTotalFilesizeDisplay }}</span>
      </p>
    </div>
  </section>

  <!-- FILES TO UPLOAD -->
  <section
    *ngIf="displayFiles.length > 0"
    class="rs-files-to-upload-zone mb-30px"
  >
    <div
      *ngFor="let file of displayFiles"
      [ngClass]="{ 'upload-successful': file.progress == 100}"
      class="file-item row align-items-center g-0"
    >
      <div class="col-auto position-relative">
        <mat-icon class="done-icon">
          done
        </mat-icon>
        <mat-icon
          *ngIf="(!file.error || file.progress == 100)"
          [ngClass]="{ 'is-uploading': (file.progress > 0 && file.progress < 100) }"
          class="file-icon"
          svgIcon="file-outline"
        >
        </mat-icon>
        <mat-icon
          *ngIf="(file.error && file.progress != 100)"
          [matTooltip]="'FILE_UPLOAD_COMPONENT.FILE_UPLOAD_FAILURE' | translate"
          class="file-icon upload-error"
          matTooltipClass="tooltip-error"
          matTooltipPosition="right"
        >
          error
        </mat-icon>
      </div>
      <div class="col text-truncate col-file-name">
        {{ file.data.name }}
      </div>
      <div
        *ngIf="_showUploadProgress"
        class="col-auto"
      >
        <mat-progress-bar
          [ngClass]="{ 'upload-successful': file.progress == 100, 'upload-error': (file.error && file.progress < 100)  }"
          [value]="file.progress"
          class="rs-progress-bar"
          mode="determinate"
        >
        </mat-progress-bar>
      </div>
      <div class="col-auto col-edit-icons">
        <button
          (click)="removeFileFromQueue(file, true);"
          *ngIf="file.progress!==100 && (file.progress===0 || file.error) && !isExternalUploading"
          [attr.data-file-name]="file.data.name"
          [attr.data-file-id]="file.id"
          data-testid="button-delete-file"
          mat-icon-button
          type="button"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>

        <button
          (click)="cancelHttpRequest(file)"
          *ngIf="file.progress!==100 && file.progress!==0 && !file.error"
          mat-icon-button
          type="button"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  </section>
</section>

<ng-template #errorDialog>
  <h2 matDialogTitle>{{ 'FILE_UPLOAD_COMPONENT.ERROR_DIALOG_TITLE' | translate }}</h2>

  <div
    mat-dialog-content
    data-testid="rs-file-error-dialog"
  >
    <ul class="file-error-list p-0 m-0">
      <li *ngFor="let file of filesNotAllowedToUploadList">

        <button
          (click)="file['display-error-info']=!file['display-error-info']"
          mat-flat-button
          type="button"
        >
          <mat-icon iconPositionStart>info</mat-icon>
          <span class="rs-font-size-12px">{{ file.data.name }}</span>
        </button>

        <ul
          [ngClass]="{ 'open': file['display-error-info'] }"
          class="ms-24px"
        >
          <li *ngIf="file.isFileEmpty">
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_EMPTY_FILE' | translate }}
          </li>

          <li *ngIf="!file.isFileTypeAllowed">
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_FILE_TYPE_NOT_ALLOWED' | translate }}
          </li>

          <li *ngIf="file.maxFilesSizeExceeded">
            {{ (allowMultiUpload ? 'FILE_UPLOAD_COMPONENT.ERROR_BATCH_SIZE_EXCEEDED' : 'FILE_UPLOAD_COMPONENT.ERROR_FILE_SIZE_EXCEEDED') | translate }}
          </li>

          <li *ngIf="file.maxFileSizeExceeded">
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_FILE_SIZE_EXCEEDED' | translate }}
          </li>

          <li *ngIf="file.maxTotalFilesExceeded">
            {{ 'FILE_UPLOAD_COMPONENT.ERROR_TOTAL_FILES_EXCEEDED' | translate }}
          </li>
        </ul>

      </li>
    </ul>
  </div>

  <mat-dialog-actions align="end">
    <button
      (click)="this.errorDialogRef.close()"
      mat-button
    >
      Ok
    </button>
  </mat-dialog-actions>
</ng-template>