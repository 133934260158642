import { takeUntil } from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive({
  selector: '[rs-two-digit-decimal-field]',
  standalone: true
})
export class RsTwoDigitDecimaNumberDirective implements AfterViewInit {
  private regex = new RegExp(/^-?\d+[.]?\d{0,2}$/g); // user can put . or , char.
  private textSelected = false;
  // input also cannot start from , or .
  private specialKeysKeyCode: number[] = [8, 9, 35, 36, 37, 39, 46, 109];
  private unsubscribeFromFormcontrolValueChanges$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    private el: ElementRef,
    private formControl: NgControl
  ) {
    this.el.nativeElement.setAttribute('autocomplete', 'off');
  }

  public ngAfterViewInit(): void {
    // In case form is prefilled, adding decimals if needed
    this.formControl
      .valueChanges!
      .pipe(
        takeUntil(this.unsubscribeFromFormcontrolValueChanges$)
      )
      .subscribe((val) => {
        if (val) {
          this.addDecimals();
        }
      });

  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    const
      fixedKey = event.key,
      current: string = this.el.nativeElement.value,
      position: number = this.el.nativeElement.selectionStart,
      next: string = [current.slice(0, position), fixedKey === 'Decimal' ? '.' : fixedKey, current.slice(position)].join('');

    if (event.ctrlKey) {
      this.textSelected = true;
    }

    if (
      !this.textSelected &&
      !this.specialKeysKeyCode.includes(event.keyCode) &&
      !event.ctrlKey && // Allow ctrl+key and check regex on onPaste event
      (next && !String(next).match(this.regex))
    ) {
      event.preventDefault();
    }

    this.textSelected = false;
  }

  /** Set textSeleted on select  */
  @HostListener('select', ['$event'])
  public select(): void {
    this.textSelected = true;
  }

  /** Check string on onPaste event  */
  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    // Allow ctrl+v if matches regex
    // @ts-ignore
    const clipboardData = event.clipboardData || window['clipboardData']; //typecasting to any

    if (!String(clipboardData.getData('text')).match(this.regex)) {
      event.preventDefault();
    }
  }

  /** Force two decimals if needed on blur */
  @HostListener('blur', ['$event'])
  public onBlur(): void {
    this.addDecimals();
  }

  /** Transform value to uppercase */
  @HostListener('focus', ['$event'])
  public onFocus(): void {
    // Unsubscribe from formcontrol value changes
    this.unsubscribeFromFormcontrolValueChanges$.next(true);
    this.unsubscribeFromFormcontrolValueChanges$.complete();
  }

  /** Do not allow drop */
  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    this.textSelected = false;
  }

  private addDecimals(): void {
    this.el.nativeElement.value = this.el.nativeElement.value.length > 0 && parseFloat(this.el.nativeElement.value as string).toFixed(2) !== 'NaN' ? parseFloat(this.el.nativeElement.value as string).toFixed(2) : '';
  }
}
