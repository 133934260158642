import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/** Tranform input value to uppercase
 *
 * Usage Example
 *
 * ```html
 <input rs-uppercase-input>
 </input>
 ```
 *
 */
@Directive({
  selector: '[rs-uppercase-input]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: RsUppercaseInputDirective, multi: true }],
  standalone: true
})
export class RsUppercaseInputDirective implements ControlValueAccessor {
  public constructor(
    public el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('blur')
  public onBlur(): void {
    this.onTouched();
  }

  /** Updates the value to uppercase on the input event. */
  @HostListener('input', ['$event.type', '$event.target.value'])
  public onInput(event: string, value: string): void {
    this.writeValue(value);
  }

  /** writeValue to uppercase */
  public writeValue(value: string): void {
    if (!value) {
      value = '';
    }

    const currentPositionStart = this.el.nativeElement.selectionStart;
    const currentPositionEnd = this.el.nativeElement.selectionEnd;
    this.renderer.setProperty(this.el.nativeElement, 'value', value.toUpperCase());
    this.el.nativeElement.setSelectionRange(currentPositionStart, currentPositionEnd);
    // Trigger on change event to apply new value to formControl/ngModel
    this.onChange(value.toUpperCase());
    this.onTouched();
  }

  // eslint-disable-next-line no-empty-function
  private onChange: Function = () => {
  };

  // eslint-disable-next-line no-empty-function
  private onTouched: Function = () => {
  };
}

