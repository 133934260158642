import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { EnvironmentConfig } from '../../modules/rs-core/models/apps-environment';
import { ENVIRONMENT } from '../../modules/rs-core/rs-core.module';
import { Environment } from '../../models';

/** rsIfEnvironment
 *
 * @param environments environments[]
 *
 * Structural Directive that will display tag only on provided environments
 *
 * Usage Example
 *
 * ```html
 <div *rsIfEnvironment='["test"]'></div> OR
 <div *rsIfEnvironment='["test", "acceptance"]'></div> OR
 ```
 *
 */
@Directive({
  selector: '[rsIfEnvironment]'
})
export class RsIfEnvironmentDirective {
  private hasView = true;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentConfig,
  ) {
  }

  @Input()
  public set rsIfEnvironment(environments: Environment[]) {
    if (environments.includes(this.environment.environment)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
