/** Interface of mandatory members to implement when using RsDynamicMatAutocompleteComponent */
export interface RsDynamicMatAutocomplete {
  rsDma_placeholder: string;
  rsDma_required: boolean;
  rsDma_options: unknown[];
  rsDma_isSearchingOptions: boolean;

  /** Will trigger each time the filter value has changes. */
  rsDma_OnFilterChange(filterValue: string): void;

  /** Function that will be used when triggering displayWith function used by matAutoComplete */
  rsDma_displayWithFunction(arg: unknown): string;
}
