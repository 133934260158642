import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { setAppSkin } from '../../../scripts/applicationsSkinSetter';
import { EnvironmentConfig } from './models/apps-environment';
import { UserInfoCookieAppName } from './modules/rs-authentication/enums/UserInfoCookieAppName.enum';
import { TranslationService } from '../../services/translate/translate.service';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RsHttpErrorsInterceptor } from './interceptors/rs-http-error-interceptor/rs-http-errors-interceptor.service';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RsMultiHttpTranslateLoader } from './modules/rs-custom-ngx-translate/rs-multi-http-translate-loader';
import { RsMissingTranslationHandler } from './modules/rs-custom-ngx-translate/rs-missing-translation-handler';
import { RsAppInitializerReplaySubject } from './components/web-component-app-initializer/classes/RsAppInitializerReplaySubject';
import { RsBlobErrorHttpInterceptor } from './interceptors/RsBlobErrorHttpInterceptor.interceptor';
import { RsAuthenticationModule } from './modules/rs-authentication/rs-authentication.module';
import { ENVIRONMENT, RS_CURRENT_APP_NAME } from './rs-core.module';
import { provideHttpClientTesting } from '@angular/common/http/testing';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RsMomentDateAdapter } from '../../shared/adapters/rs-moment-date-adapter';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { ToastrModule } from 'ngx-toastr';

setAppSkin();

const
  translationsLoaded$ = new RsAppInitializerReplaySubject(1),
  hasUserAccessToThisApp$ = new RsAppInitializerReplaySubject(1);

/**
 * @description
 *
 * The modules sets up the RsCoreModule to be used for testing.
 *
 * App initializer logic and component has been removed due to an issue with custom elements' registry.
 *
 * It avoids having to add teardown: { destroyAfterEach: false } to unit tests
 *
 * For more infos: https://stackoverflow.com/questions/73849002/unit-tests-of-angular-elements-registered-with-the-customelementregistry-as-a-h
 *
 * Imports following modules:  TranslateModule, HttpClientTestingModule
 *
 * Imports all needed providers for app to execute
 *
 * @usageNotes
 * ### Example
 *
 * ```
 * beforeEach(() => {
 *   TestBed.configureTestingModule({
 *     imports: [
 *       RsCoreTestingModule.forRoot(),
 *       RsCoreTestingModule.forRoot({
 *         ...params to override default one's
 *       })
 *     ]
 *   });
 * });
 * ```
 *
 * @publicApi
 */
@NgModule({ exports: [TranslateModule],
  imports: [
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    TranslateModule
      .forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: RsMultiHttpTranslateLoader,
          deps: [
            HttpClient,
            ENVIRONMENT,
            RS_CURRENT_APP_NAME,
            'translationsLoaded'
          ]
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: RsMissingTranslationHandler
        }
      }),
    RsAuthenticationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RsHttpErrorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RsBlobErrorHttpInterceptor,
      multi: true
    },
    {
      provide: 'translationsLoaded',
      useValue: translationsLoaded$,
    },
    {
      provide: 'conditionsToInitializeApp',
      useValue: [
        hasUserAccessToThisApp$,
        translationsLoaded$
      ]
    },
    { provide: DateAdapter, useClass: RsMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    provideHttpClient(withInterceptorsFromDi()),
    provideHttpClientTesting()
  ] })
export class RsCoreTestingModule {
  private static appInitializerSuccessCallBack?: (() => void);

  constructor(
    @Optional() @SkipSelf() private rsCoreTestingModule: RsCoreTestingModule,
    private readonly translateService: TranslationService
  ) {
    if (rsCoreTestingModule) {
      throw new Error('rsCoreTestingModule is already loaded. Import it only once!');
    }

    this.translateService.initialize();
  }

  /** @param appInitializerSuccessCallBack function to execute in case of success
   * @param appName must be the same as the ones provided by the user-info cookie. Will be used to construct Weblate translation resource url as well!
   * @param translationResources IRsTranslateLoaderTranslationResource[]
   **/
  public static forRoot({
    appInitializerSuccessCallBack,
    appName,
    environment
  }: {
    appInitializerSuccessCallBack?: (() => void),
    appName: UserInfoCookieAppName,
    environment: EnvironmentConfig
  } = {
    appName: UserInfoCookieAppName.ORD,
    environment: { environment: 'test' } as EnvironmentConfig
  }): ModuleWithProviders<RsCoreTestingModule> {

    return {
      ngModule: RsCoreTestingModule,
      providers: [
        {
          provide: ENVIRONMENT,
          useValue: environment
        },
        {
          provide: 'appInitializerSuccessCallBack',
          useValue: appInitializerSuccessCallBack
        },
        {
          provide: RS_CURRENT_APP_NAME,
          useValue: appName
        },
      ]
    };
  }
}
