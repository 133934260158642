import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import { errorHandlerFactory } from '_core/error-handlers/error-handler-factory';
import { coreServiceProviders } from '_core/services';
import {
  ENVIRONMENT,
  RsMissingTranslationHandler,
  RsMomentDateAdapter,
  RsMultiHttpTranslateLoader,
  TranslationService
} from '@lib/rs-ngx';
import { environment } from '_environments/environment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import {
  RsAuthenticationInterceptor
} from '@rs-shared-lib/modules/rs-core/modules/rs-authentication/interceptors/rs-authentication.interceptor';


registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);


@NgModule({ imports: [
  BrowserModule,
  BrowserAnimationsModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: RsMultiHttpTranslateLoader,
      deps: [HttpClient, ENVIRONMENT, 'appName'],
    },
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: RsMissingTranslationHandler,
    },
  })
],
providers: [
  { provide: DateAdapter, useClass: RsMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  { provide: ENVIRONMENT, useValue: environment },
  {
    provide: 'appName',
    useValue: 'TRACE'
  },
  { provide: ErrorHandler, useFactory: errorHandlerFactory },
  { provide: HTTP_INTERCEPTORS, useClass: RsAuthenticationInterceptor, multi: true },
  coreServiceProviders,
  provideHttpClient(withInterceptorsFromDi())
] })
export class CoreModule {
  public constructor(
		private readonly translateService: TranslationService,
		@Optional() @SkipSelf() coreModule?: CoreModule
  ) {
    if (coreModule) {
      throw new Error('CoreModule is already loaded. Import it only once!');
    }

    this.translateService.initialize();
  }
}

