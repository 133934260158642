import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

/** Will add a prefix sign before field when on focus/filled
 *
 * Usage
 *
 *```html
 Default '€'
 <mat-form-field rs-input-prefix></mat-form-field>

 <mat-form-field [rs-input-prefix]="$"></mat-form-field>
 ```
 */

@Directive({
  standalone: true,
  selector: '[rs-input-prefix]'
})
export class RsInputPrefixDirective implements OnInit {

  @Input('rs-input-prefix') public prefixSign: string = '€';

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer2
  ) {
  }

  public ngOnInit(): void {

    this.prefixSign = this.prefixSign ? this.prefixSign : '€';

    const
      prefixSpan = this.renderer.createElement('span'),
      prefixDiv = this.renderer.createElement('div'),
      spanText = this.renderer.createText(this.prefixSign);

    this.renderer.appendChild(prefixSpan, spanText);
    this.renderer.addClass(prefixSpan, 'rs-input-prefix');
    this.renderer.setAttribute(prefixSpan, 'matPrefix', '');

    this.renderer.addClass(prefixDiv, 'mat-mdc-form-field-icon-prefix');
    this.renderer.appendChild(prefixDiv, prefixSpan)

    this.renderer
      .insertBefore(
        this.hostElement.nativeElement.querySelector('.mat-mdc-form-field-flex') as HTMLSpanElement,
        prefixDiv,
        this.hostElement.nativeElement.querySelector('.mat-mdc-form-field-infix') as HTMLSpanElement
      );
  }
}
