import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/** rsNumberMaskInput
 *
 * @Optional Mask allowed chars: [0,.] Ex: 00.0 | 0000.00
 *
 * Default will be .00
 *
 * if nothing before . no limit will be added to the digits length
 *
 * if no . > No decimals will be allowed
 *
 * if no rsDecimalsInputAllowComa only dot decimal separator will be allowed
 *
 * Usage Example
 *
 * ```html
 <input rsNumberMaskInput="000.00" rsNumberMaskInputAllowComa></input> OR
 <input rsNumberMaskInput="000.00"></input> OR
 <input rsNumberMaskInput></input> OR
 <input rsNumberMaskInput rsNumberMaskInputAllowComa></input>
 ```
 *
 */

@Directive({
  selector: '[rsNumberMaskInput]',
  standalone: true
})
export class RsNumberMaskInputDirective implements ControlValueAccessor, OnInit, AfterContentInit {
  public inputElement: ElementRef;
  private prevValue: string = '';
  private _rsDecimalsInputAllowComa: boolean = false;
  private validationRegex!: RegExp;
  private invalidMask = false;

  // @Input('rsNumberMaskInput') public rsNumberMaskInputMask?: string;

  private _rsNumberMaskInput?: string;

  public constructor(
        public el: ElementRef,
        private cd: ChangeDetectorRef,
        @Self() @Optional() public controlDirective: NgControl
  ) {
    this.inputElement = el;
    this.inputElement.nativeElement.setAttribute('autocomplete', 'off');
    this.inputElement.nativeElement.setAttribute('type', 'text');
  }

  public get rsNumberMaskInputMask(): string | undefined {
    return this._rsNumberMaskInput;
  }

    @Input('rsNumberMaskInput')
  public set rsNumberMaskInputMask(value: string | undefined) {
    this._rsNumberMaskInput = value;
    this.ngOnInit();
  }

    @Input('rsNumberMaskInputAllowComa')
    public set rsNumberMaskInputAllowComa(val: boolean) {
      this._rsDecimalsInputAllowComa = coerceBooleanProperty(val);
    }

    public ngAfterContentInit(): void {
      if (this.controlDirective.value) {
        this.checkValueValidityAndWriteValue(this.controlDirective.value as string);
      }

      // Getting default or prefilled value as prevValue
      this.prevValue = this.validationRegex.test(this.controlDirective.value as string) ? this.controlDirective.value : '';

      this.cd.detectChanges();
    }

    public ngOnInit(): void {
      this._rsNumberMaskInput = this._rsNumberMaskInput || '.00';

      const regex = /^[0]{0,}([.]{0,1}[0]{0,})?$/;

      if (!regex.test(this._rsNumberMaskInput)) {
        this.invalidMask = true;
        throw `Mask "${this._rsNumberMaskInput}" format is not valid. Please use only 0 and 1 dot as decimal separator`;
      }

      this.setValidationRegex();
    }

    public registerOnChange(fn: (_: string) => void): void {
      this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
    }

    /** Updates the value on the input event. */
    @HostListener('input', ['$event', '$event.target.value'])
    public onInput(event: InputEvent, value: string): void {
      this.checkValueValidityAndWriteValue(value);
    }

    /** Reset prev value if field is cleared by clear button */
    @HostListener('ngModelChange', ['$event'])
    public ngModelChange(value: string): void {
      if (!value) {
        this.prevValue = '';
      }
    }

    /** Remove decimal separator if no decimals */
    @HostListener('blur', ['$event', '$event.target.value'])
    public onBlur(event: InputEvent, value: string): void {
      const
        regex = /^[0-9]{1,}[.|,]{1}[0-9]{0}$/,
        noDecimals = regex.test(value);

      if (noDecimals) {
        this.writeValue(value.replace('.', '').replace(',', ''));
      }
    }

    public writeValue(value: string): void {
      this.onChange(value);
      this.controlDirective.control?.patchValue(value);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-empty-function
    private onChange = (_string: string): void => {
    };

    // eslint-disable-next-line no-empty-function
    private onTouched = (): void => {
    };

    private setValidationRegex(): void {
      const
        splitedMask = this._rsNumberMaskInput!.split('.'),
        nbrDigits = splitedMask[0].length === 0 ? '' : splitedMask[0].length,
        nbrDecimals = splitedMask[1]?.length === 0 ? '' : splitedMask[1]?.length,
        separator = this._rsDecimalsInputAllowComa ? '|,' : '';

      const regex = splitedMask[1] === undefined ? `^((\\d{1,${nbrDigits}}))$` : `^((\\d{1,${nbrDigits}}((\\.${separator})\\d{0,${nbrDecimals}})?))$`;

      this.validationRegex = new RegExp(regex);

      // Set control's pattern validator and add it to eventual ones
      this.controlDirective.control?.addValidators(Validators.pattern(this.validationRegex));
      this.controlDirective.control?.updateValueAndValidity();
    }

    private checkValueValidityAndWriteValue(value: string): void {
      if (this.invalidMask) {
        throw `Mask "${this._rsNumberMaskInput}" format is not valid. Please use only 0 and 1 dot as separator`;
      }

      const
        isValueValid = this.validationRegex.test(value) || value === '',
        writeValue = isValueValid ? value : this.prevValue;

      this.prevValue = isValueValid ? value : this.prevValue;

      this.writeValue(writeValue);
      this.onTouched();
    }
}
