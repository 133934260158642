import { UntypedFormGroup } from '@angular/forms';

/** Ensure that one of the checkboxes of the formGroup is checked
 *
 * @param {number} [minRequired] number optional default = 1
 * @return { object | null } {requireOneCheckboxToBeChecked: true} | null
 */
export function requireCheckboxesToBeCheckedValidator(minRequired = 1) {
  return function validate(formGroup: UntypedFormGroup): { requireOneCheckboxToBeChecked: true } | null {
    let checked = 0;

    Object.keys(formGroup.controls).forEach((key): void => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    return checked < minRequired ? {
      requireOneCheckboxToBeChecked: true,
    } : null;
  };
}
