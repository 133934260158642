import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RentaNgxLibModule } from '@lib/rs-ngx';
import { TranslateModule } from '@ngx-translate/core';
import { CustomMaterialModule } from './custom-material-module/custom-material.module';
import { ReactiveFormsModule } from '@angular/forms';

/** @deprecated Usage will change, please read description below!!!
 *
 * @description What!!! How should we use it now ???
 *
 * - Will be used only to import/export real shared modules like CommonModule, TranslateModule.forChild (Those will be shared only in rentaNgxModule)
 * - For other modules like Material one's, please ONLY import the ones you need where you need them!
 * - For shared components, please create standalone ones and import it only where needed
 * - Same for shared directives, and pipes, please create standalone ones and import it only where needed
 **/
@NgModule({
  exports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    CustomMaterialModule,
    RentaNgxLibModule
  ]
})
export class SharedModule {
}
