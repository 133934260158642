import { Component, EventEmitter, HostBinding, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import moment, { Moment } from 'moment';
import { RsDateRange } from '../models/date-range-event';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  RsAddClearFormFieldButtonDirective
} from '../../../directives/rs-add-clear-form-field-button/rs-add-clear-form-field-button.directive';

/** Usage Example
 *
 * ```html
 <rs-date-range-control
 formControlName=":string"
 [minStartDate]=":Date - default: null"
 [minDateRangeLength]=":number - default: 0"
 [startDateDisabled]=":boolean - default: false"
 [endDateRequired]=":boolean - default: false"
 [startDateDataCy]="startDate Cypress uid"
 [endDateDataCy]="endDate Cypress uid"
 (valueChange)=":EventEmitter<RsDateRange>"
 />
 ```
 */

@Component({
  selector: 'rs-date-range-control',
  templateUrl: './rs-date-range.component.html',
  styleUrls: ['./rs-date-range.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    RsAddClearFormFieldButtonDirective
  ]
})
export class RsDateRangeComponent implements ControlValueAccessor, OnInit {
    @HostBinding('class') @Input('class') public class?: string;

    @Input() public minStartDate: Date | null = null;

    @Input() public startDateDisabled: boolean = false;

    /** If provided will set min endDate with specific days added to startDate */
    @Input() public minDateRangeLength: number = 0;

    @Input() public endDateRequired: boolean = false;

    @Input() public startDateDataCy: string = 'startDate';

    @Input() public endDateDataCy: string = 'endDate';
    public minEndDate?: Date;
    public value: RsDateRange = { startDate: null, endDate: null };
    public validators?: ValidationErrors | null;
    public isEndDateTouched: boolean = false;
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Output() private valueChange = new EventEmitter<RsDateRange>();

    constructor(@Self() @Optional() public controlDirective: NgControl) {
      controlDirective.valueAccessor = this as RsDateRangeComponent;
    }

    public ngOnInit(): void {
      this.validators = this.controlDirective?.control?.validator
        ? this.controlDirective?.control?.validator({} as AbstractControl)
        : {};
      this.controlDirective.control?.setValidators([this.validate.bind(this)]);
      this.controlDirective.control?.updateValueAndValidity();
    }

    public registerOnChange(fn: Function): void {
      this.onChange = fn;
    }

    public registerOnTouched(fn: Function): void {
      this.onTouched = fn;
    }

    public writeValue(obj: { startDate: Moment; endDate: Moment }): void {
      if (!obj) {
        return;
      }
      this.startDateChange(obj?.startDate, false);
      this.endDateChange(obj?.endDate, false);
    }

    public startDateChange(
      $event: Moment | null,
      markAsTouched: boolean = true // If called via writeValue do not mark as touched because it's a programmatic change
    ): void {
      const startDate = $event ? moment($event) : null;

      if (moment($event!).isAfter(this.value.endDate!) || $event === null) {
        this.value = { startDate, endDate: null };
      } else {
        this.value = { ...this.value, startDate }
      }

      this.minEndDate = moment($event!)
        .add(this.minDateRangeLength, 'days')
        .toDate();

      if (markAsTouched) {
        this.onTouched();
      }

      this.onChange(this.value);

      this.valueChange.emit(this.value);
    }

    public endDateChange(
      $event: Moment | null,
      markAsTouched: boolean = true // If called via writeValue do not mark as touched cause it's a programmatic change
    ): void {
      this.value = {
        ...this.value,
        endDate: $event ? moment($event) : null
      };
      if (markAsTouched) {
        this.onTouched();
      }
      this.onChange(this.value);
      this.valueChange.emit(this.value as RsDateRange);
    }

    public deleteEndDate(event: MouseEvent): void {
      this.endDateChange(null);

      // Prevent to datepicker reopen on click
      if (event) {
        event.stopPropagation();
      }
    }

    public deleteStartDate(event: MouseEvent): void {
      this.startDateChange(null);

      // Prevent to datepicker reopen on click
      if (event) {
        event.stopPropagation();
      }
    }

    // eslint-disable-next-line no-empty-function
    private onChange: Function = () => {
    };

    // eslint-disable-next-line no-empty-function
    private onTouched: Function = () => {
    };

    private validate({ value }: AbstractControl): { invalid: boolean; } | null {
      return (this.validators?.required && value?.startDate === null) ||
        (this.endDateRequired && value?.endDate === null)
        ? { invalid: true }
        : null;
    }
}
