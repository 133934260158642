<ct-audit-trail *ngIf="auditTrail">
  <ct-created-on
    *ngIf='auditTrail.createdOn'
    data-testid="audit-trail-created"
  >
    {{ 'CREATED_BY' | translate }}
    {{ auditTrail.createdBy | rsCreationEditionUser | rsNullDash }}
    <br *ngIf="multiline" />
    {{ 'ON' | translate }}
    {{ auditTrail.createdOn | date:'short':'': currentLanguage }}
  </ct-created-on>

  <ct-modified-on
    *ngIf='auditTrail.lastModifiedOn'
    data-testid="audit-trail-modified"
  >
    {{ 'EDITED_BY' | translate }}
    {{ auditTrail.lastModifiedBy | rsCreationEditionUser | rsNullDash }}
    <br *ngIf="multiline" />
    {{ 'ON' | translate }}
    {{ auditTrail.lastModifiedOn | date:'short':'': currentLanguage }}
  </ct-modified-on>
</ct-audit-trail>