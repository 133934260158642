<mat-form-field
	[rsClearFormFieldButtonCallback]="clearValue.bind(this)"
	data-testid="rs-dynamic-mat-autocomplete"
	rsAddClearFormFieldButton
>
	<mat-label>{{ placeholder | translate }}</mat-label>
	<input
		(blur)='onBlurHandler()'
		(focus)='onFocus()'
		[formControl]="filterFormControl"
		[matAutocomplete]='rsDynamicMatAutocompleteComponent'
		[required]="required"
		[rs-mat-autocomplete-enforce-selection]="{ actionOnWhenNotSelected: required ? 'error' : 'reset', optionSelected: autocompleteFormControl.control?.value }"
		data-testid="input-filter-options"
		matInput
		type="text"
	>
	<div
		class='me-8px'
		matPrefix
	>
		<mat-icon *ngIf='!isSearchingOptions; else searchingSpinner'>
			search
		</mat-icon>

		<ng-template #searchingSpinner>

			<mat-spinner
				class="search-spinner"
				diameter="21"
				matPrefix
			></mat-spinner>
		</ng-template>
	</div>

	<mat-autocomplete
		#rsDynamicMatAutocompleteComponent='matAutocomplete'
		(optionSelected)='optionSelected($event.option.value)'
		[displayWith]='displayWithFunction'
	>
		<mat-option
			*ngFor='let option of _options'
			[value]='option'
		>
			{{ displayWithFunction(option) }}
		</mat-option>
	</mat-autocomplete>

	<mat-hint
		*ngIf='showNoOptionsFoundError'
		class='rs-color--hit-pink'
	>
		{{ 'ERROR_MSG.NO_OPTIONS_FOUND_WITH_THIS_FILTER' | translate }}
	</mat-hint>

	<mat-error *ngIf="filterFormControl?.hasError('required') && required">
		{{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
	</mat-error>

	<mat-error *ngIf="filterFormControl?.hasError('optionNotSelectedFromMatAutocompleteList')">
		{{ 'ERROR_MSG.PLEASE_SELECT_AN_OPTION' | translate }}
	</mat-error>
</mat-form-field>
