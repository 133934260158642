import { Directive, ElementRef, HostListener, Input, Optional, Renderer2, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { parseInt } from 'lodash';

/** Vat Directive to add to input tag
 *
 * will prevent to enter a char that is not alphanumeric
 *
 * will transform string to uppercase
 * Usage Example
 *
 * ```html
 <input rs-vat-input></input> OR
 ```
 *
 * Tes values: BE0-438 308455
 *
 */
@Directive({
  selector: '[rs-vat-input]',
  standalone: true
})
export class VatInputDirective implements ControlValueAccessor {

  /** maxChars param
   *
   * @optional default = null
   */
  @Input({
    alias: 'rs-vat-input',
    transform: (value: number | string) => parseInt(value.toString())
  }) private maxChars: number | null = null;
  private inputElement: ElementRef;
  private textSelected = false;

  public constructor(
    public el: ElementRef,
    private renderer: Renderer2,
    @Self() @Optional() public controlDirective: NgControl
  ) {
    this.inputElement = el;
    this.inputElement.nativeElement.setAttribute('autocomplete', 'off');
    this.inputElement.nativeElement.classList.add('rs-uppercase');
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** Check string on keydown event */
  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    // Allow [ctrl+key]
    if (event.ctrlKey) {
      this.textSelected = true;
    }

    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter or ctrl+key
      [46, 8, 9, 27, 13].indexOf(event.keyCode) === -1 &&
      !(event.ctrlKey && (event.keyCode == 86 || event.keyCode == 65 || event.keyCode == 67)) && // For IE11
      !this.restrict(event.key)
    ) {
      event.preventDefault();
    }

    this.textSelected = false;
  }

  /** Check string on onPaste event  */
  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    // @ts-ignore
    const clipboardData = event.clipboardData || window['clipboardData']; //typecasting to any

    if (!this.restrict(clipboardData.getData('text') as string)) {
      this.writeValue(clipboardData.getData('text') as string);
      event.preventDefault();
    }
    this.textSelected = false;
  }

  /** Check string on onDrop event  */
  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    if (!this.restrict(event.dataTransfer!.getData('text'))) {
      event.preventDefault();
    }
    this.textSelected = false;
  }

  /** Updates the value on the input event. */
  @HostListener('input', ['$event.type', '$event.target.value'])
  public onInput(event: string, value: string): void {
    this.writeValue(value);
  }

  /** Set textSeleted on select  */
  @HostListener('select', ['$event'])
  public select(): void {
    this.textSelected = true;
  }

  /** Set textSeleted to false on click  */
  @HostListener('click', ['$event'])
  public onCLick(): void {
    this.textSelected = false;
  }

  /** Check string on change event  */
  @HostListener('change', ['$event'])
  public change(event: Event): void {
    if (!this.restrict((event.target as HTMLInputElement).value)) {
      event.preventDefault();
    }
  }

  /** Updates the value and transform to uppercase on the blur event.
   *
   * This will trigger the filed to validate
   *
   */
  @HostListener('blur', ['$event.type', '$event.target.value'])
  public onBlur(event: string, value: string): void {
    this.writeValue(value);
    this.onTouched();
  }

  /** writeValue will strip non numeric chars
   *
   */
  public writeValue(value: string): void {

    if (!value) {
      this.controlDirective.control?.patchValue('');
      this.onChange('');
      return;
    }

    const stripNonAlphaNumericChars = value.replace(/[^0-9a-z]/gi, '');

    if (stripNonAlphaNumericChars.length > 0) {
      this.controlDirective.control?.patchValue(stripNonAlphaNumericChars.toUpperCase());
      // Trigger on change event to apply new value to formControl/ngModel
      this.onChange(stripNonAlphaNumericChars);
    }
  }

  // eslint-disable-next-line no-empty-function
  private onChange: Function = () => {
  };

  // eslint-disable-next-line no-empty-function
  private onTouched: Function = () => {
  };

  /** Validates string
   * - If maxChars provided check the length
   * - Allow only alphaNumeric chars
   *
   * @param value string
   */
  private restrict(value: string): boolean {
    const reg = new RegExp('^[A-Za-z0-9]*$');
    // Max chars limit if provided and text is not selected (if selected allow to override it)
    if (
      !this.textSelected &&
      this.maxChars &&
      this.inputElement.nativeElement.value.length > (this.maxChars - 1)
    ) {
      return false;
    }

    // Must be alphaNumeric
    return reg.test(value);
  }
}
