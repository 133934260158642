export const LOAD_INDEX_OPTIONS_CONST = [
  'L0',
  'L62',
  'L63',
  'L64',
  'L65',
  'L66',
  'L67',
  'L68',
  'L69',
  'L70',
  'L71',
  'L72',
  'L73',
  'L74',
  'L75',
  'L76',
  'L77',
  'L78',
  'L79',
  'L80',
  'L81',
  'L82',
  'L83',
  'L84',
  'L85',
  'L86',
  'L87',
  'L88',
  'L89',
  'L90',
  'L91',
  'L92',
  'L93',
  'L94',
  'L95',
  'L96',
  'L97',
  'L98',
  'L99',
  'L100',
  'L101',
  'L102',
  'L103',
  'L104',
  'L105',
  'L106',
  'L107',
  'L108',
  'L109',
  'L110',
  'L111',
  'L112',
  'L113',
  'L114',
  'L115',
  'L116',
  'L117',
  'L118',
  'L119',
  'L120',
  'L121',
  'L122',
  'L123',
  'L124',
  'L125',
  'L126',
  'L128',
  'L137',
  'L156',
] as const;

export const LOAD_INDEX_OPTIONS = LOAD_INDEX_OPTIONS_CONST as unknown as string[];

