<!-- DRAWER HEADER -->
<section
  class="rs-drawer-header d-flex align-items-center"
  [ngClass]="{'justify-content-between': displayClose}"
>
  <button
    *ngIf="displayClose"
    (click)="onCloseFilters.emit()"
    data-testid="rs-overview-filters--close-button"
    mat-icon-button
    type='button'
  >
    <mat-icon>close</mat-icon>
  </button>

  <div class="action-buttons">
    <!-- CLEAR ALL BUTTON -->
    <button
      (click)="onClearFilters.emit()"
      class="rs-btn--secondary"
      data-testid="rs-overview-filters--clear-button"
      mat-button
    >
      {{ "CLEAR_FILTERS" | translate }}
    </button>

    <!-- APPLY BUTTON -->
    <button
      (click)='onApplyFilters.emit()'
      [disabled]="!canApplyFilters"
      class="rs-btn--primary"
      data-testid="rs-overview-filters--apply-button"
      mat-button
    >
      {{ "APPLY_FILTERS" | translate }}
    </button>
  </div>
</section>

<!-- DRAWER BODY -->
<section class="rs-drawer-content">
  <ng-content></ng-content>
</section>