import { SectionLoaderComponent } from '../../components/section-loader/section-loader.component';
import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';

/** Used to add a loader inside an container
 *
 * For example when reloading form field value, it can be used on the container of the field
 *
 * Will append custom SectionLoaderComponent
 * MANUAL HIDE:
 * ```html
 <anyTag
 [rs-section-loader-show]="boolean"- Default false
 [rs-section-loader-message]="string" OPTIONAL - Default ''
 [rs-section-loader-showDelay]="600"
 rs-section-loader-message-h-align="left | center | right" OPTIONAL - Default left
 rs-section-loader-message-v-align="top | center | bottom" OPTIONAL - Default center
 >
 </anyTag>
 ```
 * AUTO HIDE:
 ```html
 <anyTag
 [(rs-section-loader-show)]="var:boolean" - Default false - [(...)] Double binded
 [rs-section-loader-message]="string" OPTIONAL - Default ''
 [rs-section-loader-hideDelay]="1000"
 [rs-section-loader-showDelay]="600"
 rs-section-loader-message-h-align="left | center | right" OPTIONAL - Default left
 rs-section-loader-message-v-align="top | center | bottom" OPTIONAL - Default center
 >
 </anyTag>
 ```
 */
@Directive({
  selector: '[rs-section-loader]',
  standalone: true
})
export class RsSectionLoaderDirective {
  // @HostBinding('class') @Input('class') public class;

    /** Double binding on rs-section-loader-show
     *
     * When defining a double-binded variable, you will need to define one @Input-decorator with the variable name:
     * and one @Output-decorator with the variable name and Change after it, because this one emits the changing-event of the variable:
     *
     * ex: @Input() edit: boolean; && @Output() editChange: EventEmitter<boolean> = new EventEmitter<boolean>();
     * then use [(edit)] and when editChange.emit, it will change the parent value
     */
    @Output('rs-section-loader-showChange') public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    /** The default delay in ms before automaticly hiding the loader
     * applyed only if provided else must be done manualy with [show]
     */
    @Input('rs-section-loader-hideDelay') public hideDelay?: number;
    /** (OPTIONAL) The default delay in ms before automaticly hiding the loader
     *
     * applyed only if provided else must be done manualy with [show]
     *
     * Default 0
     */
    @Input('rs-section-loader-showDelay') public showDelay: number = 0;
    /** The horizontal alignment of the message
     *
     * 'left' | 'center' | 'right' - optional - default = 'left'
     */
    @Input('rs-section-loader-message-h-align') public hAlignMessage:
        | 'left'
        | 'center'
        | 'right' = 'left';
    /** The vertical alignment of the message
     *
     * 'top' | 'center' | 'bottom' - optional - default = 'center'
     */
    @Input('rs-section-loader-message-v-align') public vAlignMessage:
        | 'top'
        | 'center'
        | 'bottom' = 'center';
    private sectionLoaderComp: SectionLoaderComponent;

    constructor(
        private hostElement: ElementRef,
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
          SectionLoaderComponent
        ),
        componentRef = this.viewContainerRef.createComponent(componentFactory);

      this.sectionLoaderComp = componentRef.instance;
      this.renderer.appendChild(
        this.hostElement.nativeElement,
        componentRef.location.nativeElement
      );
    }

    private _show!: boolean;

    public get show(): boolean {
      return this._show;
    }

    /** Shows the loader
     *
     * boolean - default = false (hidden)
     */
    @Input('rs-section-loader-show')
    public set show(display: boolean) {
      this.sectionLoaderComp.showDelay = this.showDelay;

      this._show = this.sectionLoaderComp.show = display;

      this.sectionLoaderComp.hAlignMessage = this.hAlignMessage;
      this.sectionLoaderComp.vAlignMessage = this.vAlignMessage;

      // Reset val if autoHide
      if (display && this.hideDelay) {
        setTimeout(() => {
          this.showChange.emit(false);
        }, this.hideDelay);
      }
    }

    public get message(): string {
      return this.message;
    }

    /** The message to be displayed in the loader
     *
     * string - optional - default = ''
     */
    @Input('rs-section-loader-message')
    public set message(val: string) {
      this.sectionLoaderComp.message = val;
    }
}
