import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dataCy]',
  standalone: true,
})
export class RsDataCyDirective implements OnInit {
  @Input() public dataCy!: string;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
  }

  public ngOnInit(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'data-cy', `${this.dataCy}`);
  }
}
