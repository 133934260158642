import { Directive, DoCheck, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export class SimpleNgForRow {
  constructor(
    public $implicit: unknown,
    public index: number
  ) {
  }

  public get even(): boolean {
    return this.index % 2 === 0;
  }

  public get odd(): boolean {
    return !this.even;
  }
}

@Directive({ selector: '[rsSimpleNgFor][rsSimpleNgForOf]' })
export class SimpleNgForDirective implements DoCheck {

  @Input('rsSimpleNgForOf') public simpleNgForOf!: unknown[];

  constructor(private _viewContainer: ViewContainerRef, private _template: TemplateRef<SimpleNgForRow>) {
  }

  @Input()
  public set ngForTemplate(value: TemplateRef<SimpleNgForRow>) {
    if (value) {
      this._template = value;
    }
  }

  public ngDoCheck(): void {
    const oldLen = this._viewContainer.length;
    const newLen = this.simpleNgForOf.length;
    const minLen = Math.min(oldLen, newLen);

    // update existing rows
    for (let index = 0; index < minLen; index++) {
      const row = this.simpleNgForOf[index];
      const viewRef = this._viewContainer.get(index) as EmbeddedViewRef<SimpleNgForRow>;
      viewRef.context.$implicit = row;
    }

    // add missing rows
    for (let index = oldLen; index < newLen; index++) {
      const row = this.simpleNgForOf[index];
      this._viewContainer.createEmbeddedView(this._template, new SimpleNgForRow(row, index));
    }

    // remove superfluous rows
    for (let index = oldLen - 1; index >= newLen; index--) {
      this._viewContainer.remove(index);
    }
  }
}
