import { ErrorHandler } from '@angular/core';
import { SentryErrorHandler } from './sentry-error-handler';
import { environment } from '_environments/environment';

export function errorHandlerFactory(): ErrorHandler {
  if (environment.enableSentryMonitoring) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
