import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[rs-alpha-numeric-input]',
  standalone: true
})
export class RsAlphanumericInputDirective {
  public inputElement: ElementRef;
    /** maxChars param
     *
     * @optional default = null
     */
  // eslint-disable-next-line @typescript-eslint/member-ordering
    @Input('rs-alpha-numeric-input') private maxChars: number | null | string = null;
    private textSelected = false;

    public constructor(
        public el: ElementRef
    ) {
      this.inputElement = el;
      this.inputElement.nativeElement.setAttribute('autocomplete', 'off');
    }

    /** Check string on keydown event */
    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
      // Allow [ctrl+key]
      if (event.ctrlKey) {
        this.textSelected = true;
      }

      if (
      // Allow: Delete, Backspace, Tab, Escape, Enter or ctrl+key
        [46, 8, 9, 27, 13].indexOf(event.keyCode) === -1 &&
            !(event.ctrlKey && (event.keyCode == 86 || event.keyCode == 65 || event.keyCode == 67)) && // For IE11
            !this.restrict(event.key)
      ) {
        event.preventDefault();
      }

      this.textSelected = false;
    }

    /** Check string on onPaste event  */
    @HostListener('paste', ['$event'])
    public onPaste(event: ClipboardEvent): void {
      // @ts-ignore
      const clipboardData = event.clipboardData || window['clipboardData']; //typecasting to any
      if (!this.restrict(clipboardData.getData('text') as string)) {
        event.preventDefault();
      }
      this.textSelected = false;
    }

    /** Check string on onDrop event  */
    @HostListener('drop', ['$event'])
    public onDrop(event: DragEvent): void {
      if (!this.restrict(event.dataTransfer!.getData('text'))) {
        event.preventDefault();
      }
      this.textSelected = false;
    }

    /** Set textSeleted on select  */
    @HostListener('select', ['$event'])
    public select(): void {
      this.textSelected = true;
    }

    /** Set textSeleted to false on click  */
    @HostListener('click', ['$event'])
    public onCLick(): void {
      this.textSelected = false;
    }

    /** Check string on change event  */
    @HostListener('change', ['$event'])
    public change(event: ClipboardEvent): void {
      if (!this.restrict((event.target as HTMLInputElement).value)) {
        event.preventDefault();
      }
    }

    /** Validates string
     * - If maxChars provided check the length
     * - Allow only alphaNumeric chars
     *
     * @param value string
     */
    private restrict(value: string): boolean {
      const reg = new RegExp('^[A-Za-z0-9]*$');
      // Max chars limit if provided and text is not selected (if selected allow to override it)
      if (
        !this.textSelected &&
            this.maxChars &&
            this.inputElement.nativeElement.value.length > (Number(Number(this.maxChars) - 1))
      ) {
        return false;
      }

      // Must be alphaNumeric
      return reg.test(value);
    }
}
