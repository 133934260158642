import { AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MomentInput } from 'moment';

interface RsPreventPastDateError {
  'past-date-is-not-allowed': {
    value: moment.MomentInput,
    valid: boolean
  }
}

type RsPreventPastDateValidatorError = RsPreventPastDateError | null;

declare module '@angular/forms' {
  export namespace Validators {
    /** ***Validates a date to ensure it's not in the past.***
     * ***
     * @param {AbstractControl<MomentInput>} control - The control to validate.
     * @returns {RsPreventPastDateValidatorError | null} An error object if the date is in the past, null otherwise.
     */
    let rsPreventPastDateValidator: (control: AbstractControl) => RsPreventPastDateValidatorError;
  }
}

Validators.rsPreventPastDateValidator = (control: AbstractControl<MomentInput>): RsPreventPastDateValidatorError => {
  return control.rsHasValue() ? validateDate(control) : null;
}

function validateDate(control: AbstractControl<MomentInput>): RsPreventPastDateValidatorError {
  return moment(new Date()).isSameOrBefore(control.value, 'days') ? null : {
    'past-date-is-not-allowed': {
      value: control.value,
      valid: false
    }
  }
}
