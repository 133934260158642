import { Directive, ViewChild } from '@angular/core';
import { combineLatest, Observable, pairwise, startWith } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { TableOptionsEvents } from './models/models';
import { rsTapOnce } from '../../utils/custom-rxjs-operators/rs-tap-once/rsTapOnce';

@Directive()
export abstract class AbstractTableWithPaginationAndSortHandlers<SortActiveType> {
  @ViewChild(MatPaginator, { static: true }) protected paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) protected sort!: MatSort;
  protected pageSizeOptions = [5, 10, 20];
  protected readonly tableEvents$ = this.tableEventsHandler$;

  protected constructor() {
  }

  private tableEventsHandler$(
    startWithParams: TableOptionsEvents<SortActiveType>,
  ): Observable<TableOptionsEvents<SortActiveType>> {
    return combineLatest([
      this.paginator.page
        .pipe(
          startWith(startWithParams.pageEvent, startWithParams.pageEvent),
          pairwise(),
          map(([prevPageEvent, currentPageEvent]) => {
            this.paginator.pageIndex = prevPageEvent.pageSize !== currentPageEvent.pageSize ? 0 : currentPageEvent.pageIndex;

            return {
              ...currentPageEvent,
              pageIndex: this.paginator.pageIndex
            };
          })
        ),
      this.sort.sortChange.pipe(
        startWith(startWithParams.sort),
        map((params) => ({
          active: params.direction ? params.active : '',
          direction: params.direction
        })),
        rsTapOnce(() => {
          this.sort.active = startWithParams.sort.active as unknown as string;
          this.sort.direction = startWithParams.sort.direction;
        })
      )
    ])
      .pipe(
        map(([pageEvent, sort]) => ({
          pageEvent: pageEvent,
          sort: sort as { active: SortActiveType, direction: Sort['direction'] },
        }))
      );
  }
}
