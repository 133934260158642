import { AbstractControl } from '@angular/forms';

/** ### Will return boolean based on Abstract control value
 *
 * * Created to avoid Boolean type conversion pitfall (0 considered as false)
 *
 * @return boolean -- true if it has value (even 0) else false
 *
 * */
AbstractControl
  .prototype
  .rsHasValue = function (): boolean {
    return this.value != undefined && this.value.toString().length > 0;
  }

/** ### Will return form or based on Abstract control value
 *
 * * Created to avoid Boolean type conversion pitfall (0 considered as false)
 *
 * @return boolean -- true if it has value (even 0) else false
 *
 * */
AbstractControl
  .prototype
  .rsFormGroupValue = function<T>(): T {
    return this.value as T;
  }

declare module '@angular/forms' {
  interface AbstractControl {
    /** ### Will return boolean based on Abstract control value
     *
     * * Created to avoid Boolean type conversion pitfall (0 considered as false)
     *
     * @return boolean -- true if it has value (even 0) else false
     *
     * */
    rsHasValue: Function;
    rsFormGroupValue: <T>() => T;
  }
}
