import { AfterViewInit, DestroyRef, Directive, EventEmitter, Host, Input, Output, Self } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Usage:
 *
 * If rs-mat-autocomplete-enforce-selection has no provided action, it will display an error
 * ```html
 <input
 rs-mat-autocomplete-enforce-selection
 (rs-mat-autocomplete-enforce-selection-no-selection)="(Optional) Function to execute on blur if value and no selection done"
 > >> Will set an error
 OR
 <input
 [rs-mat-autocomplete-enforce-selection]="{ actionOnWhenNotSelected: 'error' | 'reset', optionSelected: autocompleteFormControl.control?.value }"
 (rs-mat-autocomplete-enforce-selection-no-selection)="(Optional) Function to execute on blur if value and no selection done"
 > error = set field error | reset = reset() form control
 ...
 </input>
 ```
 */
@Directive({
  selector: '[rs-mat-autocomplete-enforce-selection]',
  standalone: true
})
export class MatAutocompleteTriggerEnforceSelectionDirective implements AfterViewInit {
  @Input({ required: true }) public matAutocomplete!: MatAutocomplete;
  @Input({
    alias: 'rs-mat-autocomplete-enforce-selection',
    transform: (value: '' | DirectiveOptions) => {
      return value === '' ? {
        actionOnWhenNotSelected: 'error',
        optionSelected: null
      } : value;
    }
  })
  public directiveOptions: DirectiveOptions = {
      actionOnWhenNotSelected: 'error',
      optionSelected: null
    };

  @Output('rs-mat-autocomplete-enforce-selection-no-selection')
  public rsMatAutocompleteEnforceSelectionCallback: EventEmitter<NonSelectionBehavior> = new EventEmitter();

  public constructor(
    @Host() @Self() private readonly autoCompleteTrigger: MatAutocompleteTrigger,
    @Host() private readonly ngControl: NgControl,
    private destroyRef$: DestroyRef
  ) {
  }

  public ngAfterViewInit(): void {
    this.autoCompleteTrigger.panelClosingActions
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe((matOptionSelectionChange) => {

        if (!matOptionSelectionChange || !matOptionSelectionChange.source) {
          const optionSelected =
            this.matAutocomplete.options
              .map(option => option.value)
              .find(option => option === this.ngControl.value);

          if (this.directiveOptions.optionSelected) {
            this.ngControl.control?.setValue(this.directiveOptions.optionSelected);
            return;
          }

          if (
            optionSelected == null
            && this.ngControl.control?.value != ''
            && this.ngControl.control?.rsHasValue()
          ) {
            switch (this.directiveOptions.actionOnWhenNotSelected) {
              case 'reset':
                this.ngControl.control?.reset();
                this.rsMatAutocompleteEnforceSelectionCallback.emit('reset');
                break;

              case 'error':
              default:
                this.ngControl.control.setErrors({ optionNotSelectedFromMatAutocompleteList: true });
                this.rsMatAutocompleteEnforceSelectionCallback.emit('error');
                break;
            }
          }
        }
      });
  }
}

export type DirectiveOptions = {
  actionOnWhenNotSelected: NonSelectionBehavior;
  optionSelected: unknown;
};
type NonSelectionBehavior = 'error' | 'reset';
