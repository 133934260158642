import { NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { RsNgrxFormDirective } from './directives/ngrx-form/ngrx-form.directive';
import * as moment from 'moment';
import {
  RsAddClearFormFieldButtonDirective
} from '../directives/rs-add-clear-form-field-button/rs-add-clear-form-field-button.directive';
import {
  MatAutocompleteTriggerEnforceSelectionDirective
} from '../directives/mat-autocomplete-trigger-enforce-selection/mat-autocomplete-trigger-enforce-selection.directive';
import { TranslationService } from '../services/translate/translate.service';
import { RsDataCyDirective } from '../directives/data-cy/data-cy.directive';

// Registering some locals to be able to used it for numbers and dates
registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);

@NgModule({
  declarations: [RsNgrxFormDirective],
  imports: [
    RsAddClearFormFieldButtonDirective,
    RsDataCyDirective,
    MatAutocompleteTriggerEnforceSelectionDirective
  ],
  exports: [
    RsDataCyDirective,
    RsNgrxFormDirective,
    RsAddClearFormFieldButtonDirective,
    MatAutocompleteTriggerEnforceSelectionDirective
  ]
})
export class RsSharedModule {
  public constructor(
        private translateService: TranslateService,
        private readonly translationService: TranslationService,
        private dateAdapter: DateAdapter<Date>
  ) {
    this.translationService.initialize();
    this.dateAdapter.setLocale(this.translateService.currentLang || null);

    /** Adapt local for datepicker and change the mask based on local */
    this.translateService
      .onLangChange
      .subscribe((event: LangChangeEvent): void => {
        this.dateAdapter.setLocale(event.lang);
        moment.locale(event.lang);
      });
  }
}
