import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, Self } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** Usage Example
 *
 * ```html
 <rs-dynamic-mat-autocomplete
 [required]="boolean"
 [placeholder]='Translatable key'
 [formControlName]="formControlName"
 (onInputFilterOptionsChange)='onFilterChangeFunction($event)'
 [options]='[options]'
 [displayWithFunction]='displayWithFunction.bind(this)'
 [isSearchingOptions]='boolean'
 ></rs-dynamic-mat-autocomplete>
 ```
 */

@Component({
  selector: 'rs-dynamic-mat-autocomplete[formControlName][displayWithFunction][options][onInputFilterOptionsChange][placeholder]',
  templateUrl: './rs-dynamic-mat-autocomplete.component.html',
  styleUrls: ['./rs-dynamic-mat-autocomplete.component.scss']
})
export class RsDynamicMatAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {

    /** Translatable key */
    @Input() public placeholder!: string;

    /** Will set field as required
     *
     * @Optional: default = false
     * */
    @Input() public required = false;

    /** Used to remove values from options.
     *
     * Ex of use: when having two rs-dynamic-mat-autocomplete and value can be selected only once
     * */
    @Input() public bannedValue?: unknown;

    /** Function that will be used when triggering displayWith function used by matAutoComplete */
    @Input() public displayWithFunction!: (option: unknown) => string;

    /** Will trigger search spinner
     *
     * @Optional: default = false
     *
     * */
    @Input() public isSearchingOptions = false;
    public filterFormControl = new UntypedFormControl();
    public showNoOptionsFoundError = false;
    /** Will emit when user types a new search */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Output() private onInputFilterOptionsChange = new EventEmitter<string>();
    private readonly $destroy = new Subject();

    constructor(
        private readonly injector: Injector,
        private readonly translateService: TranslateService,
        @Self() public autocompleteFormControl: NgControl
    ) {
      autocompleteFormControl.valueAccessor = this as RsDynamicMatAutocompleteComponent;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public _options!: unknown[];

    @Input()
    public set options(options: unknown[]) {
      this.shouldShowNoOptionErrorMessage(options);

      this._options = options;
    }

    public get currentLang(): string {
      return this.translateService.currentLang;
    }

    public ngOnInit(): void {
      this.onFilterValueChange();
      this.onLanChangeHandler();
      this.onBlurHandler();
    }

    public ngOnDestroy(): void {
      this.$destroy.next(true);
      this.$destroy.complete();
    }

    public optionSelected(value: unknown): void {
      this.onChange(value);
      this.onTouch(value);
    }

    public clearValue(): void {
      this.filterFormControl.reset('');
      this.autocompleteFormControl.control?.reset('');
      this._options = [];
    }

    public registerOnChange(fn: Function): void {
      this.onChange = fn;
    }

    public registerOnTouched(fn: Function): void {
      this.onTouch = fn;
    }

    public writeValue(value: unknown): void {
      this.filterFormControl.patchValue(value);
      this.onChange(value);
      this.onTouch(value);
    }

    public onFocus(): void {
      this.onTouch();
    }

    public onBlurHandler(): void {
      if (this.autocompleteFormControl.control!.rsHasValue()) {
        this.showNoOptionsFoundError = false;
      }
    }

    private shouldShowNoOptionErrorMessage(options: unknown[]): void {
      const
        fControl = this.filterFormControl,
        ngControl = this.autocompleteFormControl.control;

      this.showNoOptionsFoundError = options.length === 0 && fControl.rsHasValue() && fControl.value !== ngControl?.value ? true : false;
    }

    private onLanChangeHandler(): void {
      // TT: Needed to translate mat autocomplete display value once selected then language is switched
      this.translateService
        .onLangChange
        .pipe(takeUntil(this.$destroy))
        .subscribe(() => {
          this.filterFormControl.setValue(
            this.autocompleteFormControl.control?.value,
            {
              emitEvent: false,
              onlySelf: true
            }
          );
        });
    }

    private onFilterValueChange(): void {
      this
        .filterFormControl
        .valueChanges
        .pipe(
          debounceTime(500),
          takeUntil(this.$destroy)
        )
        .subscribe((value: string) => {

          this.onTouch();

          if (typeof value === 'string' && !value.trim()) {
            this.clearFilterValue();
            return;
          }

          this.onInputFilterOptionsChange.emit(value);
          this._options = []; // Resetting options ot close panel for new searches
        });
    }

    private clearFilterValue(): void {
      this.filterFormControl.setValue('', { emitEvent: false });
      this._options = [];
    }

    private onChange: Function = () => {
      // Needed on registerOnChange
    };

    private onTouch: Function = () => {
      // Needed on registerOnTouched
    };
}
