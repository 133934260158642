/* eslint-disable id-length */
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as bigInt_ from 'big-integer';

const bigInt = bigInt_;

interface MapObj {
  A: string;
  B: string;
  C: string;
  D: string;
  E: string;
  F: string;
  G: string;
  H: string;
  I: string;
  J: string;
  K: string;
  L: string;
  M: string;
  N: string;
  O: string;
  P: string;
  Q: string;
  R: string;
  S: string;
  T: string;
  U: string;
  V: string;
  W: string;
  X: string;
  Y: string;
  Z: string;
}

/** validate two first digits of Vin control number
 *
 * @param vinNumberControlName string - To retrieve vin number value
 * (if nested formControl, pass the entire path. Ex: vehicle.vin)
 *
 */
export function VinControlNumberValidator(vinNumberControlName: string): ValidatorFn {

  let vinNumber: string;

  return (control: AbstractControl): { [key: string]: unknown } | null => {

    if (control.value) {

      if (!control.parent || !control.parent.root) {
        console.error('No form element found. Please check that the field is inside a form');
        return null;
      }

      vinNumber = control.parent.root.get(vinNumberControlName)?.value;

      if (!vinNumber || vinNumber.length != 17) {
        console.error('Vin number is not provided or incorect');
        return null;
      }

      const
        mapObj: MapObj = {
          A: '2',
          B: '3',
          C: '4',
          D: '5',
          E: '6',
          F: '7',
          G: '8',
          H: '9',
          I: '1',
          J: '1',
          K: '2',
          L: '3',
          M: '4',
          N: '5',
          O: '0',
          P: '7',
          Q: '8',
          R: '9',
          S: '1',
          T: '2',
          U: '3',
          V: '4',
          W: '5',
          X: '6',
          Y: '7',
          Z: '8'
        },
        re = new RegExp(Object.keys(mapObj).join('|'), 'gi'),
        newVin = vinNumber.replace(re, (matched) => {

          return mapObj[matched as keyof MapObj];
        }),
        modulo = bigInt(newVin).mod(97),
        // @ts-ignore
        checkNumber = 97 - modulo;

      // toString() used in case typeof is number (<input type="number">)
      const isValid: boolean = Number(control.value.toString().substring(0, 2)) === checkNumber;

      return isValid ? null : { 'vin-control-number': { value: control.value, valid: false } };
    } else {
      return null;
    }
  };
}
