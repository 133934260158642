/** Download document
 *
 * @param { Blob } blob Blob
 *
 * @param { string } fileName string
 *
 */
export function rsDownloadDocumentHandler(
    blob: Blob,
    fileName: string
): void {
  // Create a link to add a custom file name when downloading
  const element = document.createElement('a');
  element.href = URL.createObjectURL(blob);
  // get filename from header provided from backend
  element.download = fileName;
  document.body.appendChild(element);

  // For cypress test
  // Do not attempt to actually download the file in test.
  // Just leave the anchor in there. Ensure your code doesn't
  // automatically remove it either.
  // @ts-ignore
  if (window['Cypress']) {
    return;
  }

  element.click();
}
