<section class="container-fluid table-container position-relative p-0">
  <aside
    [ngClass]="{'open': isOpen}"
    class="table-columns-reorderer"
  >
    <button
      (click)="toggleTcrState();"
      class="btn--columns-settings"
      data-testid="tcr-toggle-display-button"
      mat-icon-button
      type="button"
    >
      <mat-icon>settings</mat-icon>
    </button>

    <div class="settings-box">
      <div class="hd-area">
        <div class="row">
          <div class="col-auto">
            <h3>{{ headerLabel }}</h3>
          </div>
          <div class="col text-end">
            <button
              (click)="expandTableToggler();"
              *ngIf="expandableArea"
              class="btn-expand"
              mat-icon-button
              type="button"
            >
              <mat-icon>{{expandTable ? 'unfold_less' : 'unfold_more'}}</mat-icon>
            </button>
            <button
              (click)="toggleTcrState();"
              class="me-15px"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div
        (cdkDropListDropped)="cdkDropListDropped($event);"
        cdkDropList
        class="columns-drag-container"
      >
        <div
          *ngFor="let column of tableColumnsState; let i = index;"
          [cdkDragDisabled]="!isColumnAllowed(column.columnId)"
          [dataCy]="'rs-drag-col-'+column.columnId"
          [attr.data-testid]="'tcr--drag-col-'+column.columnId"
          cdkDrag
          cdkDragLockAxis="y"
          class="drag-item drag-col-{{column.columnId}}"
        >
          <div
            *cdkDragPlaceholder
            class="drag-placeholder"
          ></div>
          <mat-checkbox
            (change)="column.display = $event.checked; saveAndEmitTabelState();"
            [checked]="column.display === undefined ? true : column.display"
          >
            {{ (column.label) | translate }}
          </mat-checkbox>
          <i class="material-icons drag-indicator">drag_indicator</i>
        </div>
      </div>
    </div>
  </aside>

  <!-- backdrop to handle closing of tcr -->
  <div
    (click)="toggleTcrState();"
    *ngIf="isOpen"
    class="backdrop"
  >
  </div>

  <ng-content></ng-content>
</section>